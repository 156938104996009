* {
  box-sizing: border-box; }
  *:hover, *:focus {
    outline: none; }
  *:before, *:after {
    box-sizing: border-box; }

html {
  min-height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  height: 100%;
  min-width: 320px; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

p {
  margin: 0; }

ul,
ol {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

figcaption,
figure,
main {
  display: block; }

figure {
  margin: 0; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 0.9em; }

a {
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bold; }

i,
em {
  font-style: italic; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

fieldset {
  padding: 0; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline;
  /* 2 */ }

textarea {
  overflow: auto; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

table {
  border-collapse: collapse; }

@font-face {
  font-family: 'fabrikat';
  src: url("../fonts/fabrikat_light_2-webfont.woff2") format("woff2"), url("../fonts/fabrikat_light_2-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'fabrikat';
  src: url("../fonts/fabrikat_regular-webfont.woff2") format("woff2"), url("../fonts/fabrikat_regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'fabrikat bold';
  src: url("../fonts/fabrikat-bold.woff") format("woff");
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'fabrikat bold itiliac';
  src: url("../fonts/fabrikat-bold.woff") format("woff");
  font-style: normal;
  font-display: swap; }

a.postings-link {
  text-decoration: underline;
  color: #737578; }

body {
  background: #fff;
  color: #221C35;
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }
  @media (min-width: 1200px) {
    body {
      font-size: 18px;
      line-height: 28px; } }
  @media (min-width: 1800px) {
    body {
      font-size: 20px;
      line-height: 30px; } }
  .show-hero-popup body,
  .nav-opened body {
    overflow: hidden !important; }
  body.sticky-footer {
    display: flex;
    flex-direction: column; }
    body.sticky-footer > * {
      max-width: 100%; }
  body.safari h1, body.safari h2, body.safari h3, body.safari h4, body.safari h5, body.safari h6,
  body.safari .h1, body.safari .h2, body.safari .h3, body.safari .h4, body.safari .h5, body.safari .h6 {
    font-weight: 500; }
  body.safari.touch .button, body.safari.touch .content button, .content body.safari.touch button, body.safari.touch input[type="submit"] {
    font-weight: 500; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "fabrikat bold", sans-serif;
  font-weight: 700;
  color: #221C35; }
  .content > h1,
  .content .hs_cos_wrapper h1, .content > h2,
  .content .hs_cos_wrapper h2, .content > h3,
  .content .hs_cos_wrapper h3, .content > h4,
  .content .hs_cos_wrapper h4, .content > h5,
  .content .hs_cos_wrapper h5, .content > h6,
  .content .hs_cos_wrapper h6, .content >
  .h1,
  .content .hs_cos_wrapper
  .h1, .content > .h2,
  .content .hs_cos_wrapper .h2, .content > .h3,
  .content .hs_cos_wrapper .h3, .content > .h4,
  .content .hs_cos_wrapper .h4, .content > .h5,
  .content .hs_cos_wrapper .h5, .content > .h6,
  .content .hs_cos_wrapper .h6 {
    margin: 20px 0px 10px 0px; }
    .content > h1:first-child,
    .content .hs_cos_wrapper h1:first-child, .content > h2:first-child,
    .content .hs_cos_wrapper h2:first-child, .content > h3:first-child,
    .content .hs_cos_wrapper h3:first-child, .content > h4:first-child,
    .content .hs_cos_wrapper h4:first-child, .content > h5:first-child,
    .content .hs_cos_wrapper h5:first-child, .content > h6:first-child,
    .content .hs_cos_wrapper h6:first-child, .content >
    .h1:first-child,
    .content .hs_cos_wrapper
    .h1:first-child, .content > .h2:first-child,
    .content .hs_cos_wrapper .h2:first-child, .content > .h3:first-child,
    .content .hs_cos_wrapper .h3:first-child, .content > .h4:first-child,
    .content .hs_cos_wrapper .h4:first-child, .content > .h5:first-child,
    .content .hs_cos_wrapper .h5:first-child, .content > .h6:first-child,
    .content .hs_cos_wrapper .h6:first-child {
      margin-top: 0; }

h1, .h1 {
  font-size: 45px;
  line-height: 50px; }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 56px;
      line-height: 64px; } }
  @media (min-width: 1440px) {
    h1, .h1 {
      font-size: 72px;
      line-height: 72px; } }
  @media (min-width: 1800px) {
    h1, .h1 {
      font-size: 90px;
      line-height: 100px; } }

h2, .h2 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0; }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 48px;
      line-height: 56px; } }
  @media (min-width: 1440px) {
    h2, .h2 {
      font-size: 56px;
      line-height: 66px; } }
  @media (min-width: 1800px) {
    h2, .h2 {
      font-size: 70px;
      line-height: 80px; } }

h2.press-release {
  font-family: "fabrikat bold", sans-serif;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  font-style: italic;
  font-weight: 200; }
  @media (min-width: 1200px) {
    h2.press-release {
      font-size: 30px;
      line-height: 110%; } }
  @media (min-width: 1800px) {
    h2.press-release {
      font-size: 30px;
      line-height: 110%; } }

h3, .h3 {
  font-size: 32px;
  line-height: 40px; }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 40px;
      line-height: 50px; } }
  @media (min-width: 1440px) {
    h3, .h3 {
      font-size: 48px;
      line-height: 52px; } }
  @media (min-width: 1800px) {
    h3, .h3 {
      font-size: 55px;
      line-height: 70px; } }
  @media (min-width: 1200px) {
    .content h3, .content .h3 {
      margin-top: 20px;
      margin-bottom: 10px; } }
  @media (min-width: 1800px) {
    .content h3, .content .h3 {
      margin-top: 20px; } }
  .content h3:first-child, .content .h3:first-child {
    margin-top: 20px; }
  .content h3:first-child:last-child, .content .h3:first-child:last-child {
    margin-bottom: 10px; }
  @media (min-width: 1200px) {
    .content.job-details h3, .content.job-details .h3 {
      margin-top: 30px; } }
  @media (min-width: 1800px) {
    .content.job-details h3, .content.job-details .h3 {
      font-size: 40px;
      line-height: 50px;
      margin-top: 41px; } }

h4, .h4 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0; }
  @media (min-width: 1440px) {
    h4, .h4 {
      font-size: 40px;
      line-height: 50px; } }
  @media (min-width: 1800px) {
    .content h4, .content .h4 {
      margin-bottom: 10px; } }
  .content h4:last-child, .content .h4:last-child {
    margin-bottom: 10px; }

h5, .h5 {
  font-size: 24px;
  line-height: 32px; }
  @media (min-width: 1440px) {
    h5, .h5 {
      font-size: 35px;
      line-height: 42px; } }
  .content h5, .content .h5 {
    margin-bottom: 25px; }
    @media (min-width: 1200px) {
      .content h5, .content .h5 {
        margin-bottom: 10px; } }
    @media (min-width: 1800px) {
      .content h5, .content .h5 {
        margin-bottom: 10px; } }
    .content h5:last-child, .content .h5:last-child {
      margin-bottom: 10px; }

h6, .h6 {
  font-size: 22px;
  line-height: 30px; }
  @media (min-width: 1440px) {
    h6, .h6 {
      font-size: 30px;
      line-height: 35px; } }
  @media (min-width: 1200px) {
    .content h6, .content .h6 {
      margin-bottom: 10px; } }
  @media (min-width: 1800px) {
    .content h6, .content .h6 {
      margin-bottom: 10px; } }

h6 a {
  color: #00B2A9; }

h6 a:hover {
  text-decoration: underline;
  color: #00B2A9; }

.content p {
  margin-bottom: 18px; }
  @media (min-width: 1200px) {
    .content p {
      margin-bottom: 18px; } }
  .content p:last-child {
    margin-bottom: 18px; }
  .content p a {
    color: #2ab1ac; }
    .no-touch .content p a:hover {
      text-decoration: underline; }
  .content p a.capabilities {
    color: rgba(27, 32, 49, 0.8); }
    .no-touch .content p a.capabilities:hover {
      text-decoration: none; }

strong {
  font-weight: 600; }

img {
  display: inline-block;
  max-width: 100%;
  height: auto; }
  .content > img,
  .content p > img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 30px; }
    @media (min-width: 1800px) {
      .content > img,
      .content p > img {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .content > img:first-child,
    .content p > img:first-child {
      margin-top: 0; }
    .content > img:first-child:last-child,
    .content p > img:first-child:last-child {
      margin-bottom: 30px; }

.content ul {
  margin: 20px 0;
  margin-left: 40px; }
  @media (min-width: 1800px) {
    .content ul {
      margin: 10px 0 30px; } }
  .content ul:first-child {
    margin-top: 0; }
  .content ul:first-child:last-child {
    margin-bottom: 10px; }
  .content ul li {
    position: relative;
    padding-left: 11px;
    margin-bottom: 10px; }
    @media (min-width: 1200px) {
      .content ul li {
        margin-bottom: 10px;
        padding-left: 14px; } }
    .content ul li:last-child {
      margin-bottom: 10px; }
    .content ul li:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba(27, 32, 49, 0.8);
      counter-increment: none; }
      @media (min-width: 1200px) {
        .content ul li:before {
          top: 11px; } }

@media (min-width: 1200px) {
  .content.job-details ul {
    margin: 30px 0; } }

.content.job-details ul li {
  margin: 8px 0px 8px 0px; }
  .content.job-details ul li::before {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    display: table-cell; }

@media (min-width: 1200px) {
  .content.job-details h3 + ul {
    margin-top: -9px; } }

ol {
  counter-reset: counterName;
  margin-top: 10px;
  margin-left: 40px;
  list-style: decimal; }
  ol li:before {
    counter-increment: counterName;
    content: counters(counterName, "-") ". "; }
  .content ol li {
    margin-bottom: 10px; }
    @media (min-width: 1800px) {
      .content ol li {
        margin-bottom: 10px; } }
    .content ol li:last-child {
      margin-bottom: 10px; }
  ol ul,
  ol ol {
    margin: 0;
    padding-left: 20px; }

ol a {
  color: #00B2A9; }

ol a:hover {
  text-decoration: underline; }

a {
  text-decoration: none;
  outline: none;
  font: inherit;
  color: inherit; }
  a:focus, a:active {
    color: inherit;
    text-decoration: none; }
  a:hover {
    text-decoration: none; }
  .no-touch .content a {
    transition: all .3s; }
    .no-touch .content a:hover {
      color: #00B2A9; }

.form-group {
  display: flex;
  flex-direction: column-reverse; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="search"] {
  display: inline-block;
  height: 40px;
  padding: 5px 20px;
  border: 1px solid #cdd3e0;
  border-radius: 25px;
  font-size: 18px;
  vertical-align: middle;
  background: #ffffff;
  color: #1b2031;
  outline: none;
  -webkit-appearance: none;
  -webkit-border-radius: 25px; }
  @media (min-width: 1200px) {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="search"] {
      height: 45px;
      padding: 7px 20px; } }
  @media (min-width: 1800px) {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="search"] {
      height: 50px;
      padding: 10px 20px; } }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder {
    color: rgba(27, 32, 49, 0.5); }
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="tel"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder {
    opacity: 1;
    color: rgba(27, 32, 49, 0.5); }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder {
    opacity: 1;
    color: rgba(27, 32, 49, 0.5); }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder {
    color: rgba(27, 32, 49, 0.5); }
  input[type="text"] + label,
  input[type="password"] + label,
  input[type="email"] + label,
  input[type="tel"] + label,
  input[type="number"] + label,
  input[type="search"] + label {
    margin-bottom: 7px; }
  input[type="text"].wpcf7-not-valid,
  input[type="password"].wpcf7-not-valid,
  input[type="email"].wpcf7-not-valid,
  input[type="tel"].wpcf7-not-valid,
  input[type="number"].wpcf7-not-valid,
  input[type="search"].wpcf7-not-valid {
    border-color: #e40000; }
    input[type="text"].wpcf7-not-valid + label,
    input[type="password"].wpcf7-not-valid + label,
    input[type="email"].wpcf7-not-valid + label,
    input[type="tel"].wpcf7-not-valid + label,
    input[type="number"].wpcf7-not-valid + label,
    input[type="search"].wpcf7-not-valid + label {
      color: #e40000; }

textarea {
  display: inline-block;
  border: 1px solid #cdd3e0;
  vertical-align: middle;
  background: #ffffff;
  color: #1b2031 !important;
  outline: none;
  -webkit-appearance: none;
  resize: none;
  width: 100% !important;
  box-sizing: border-box !important;
  height: 100px !important;
  padding: 5px 20px !important;
  font-size: 18px !important;
  border-radius: 20px !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 1200px) {
    textarea {
      height: 120px !important;
      padding: 7px 20px !important; } }
  @media (min-width: 1800px) {
    textarea {
      height: 160px !important;
      padding: 10px 20px !important; } }
  textarea::-webkit-input-placeholder {
    color: #1b2031 !important; }
  textarea:-moz-placeholder {
    opacity: 1;
    color: #1b2031 !important; }
  textarea::-moz-placeholder {
    opacity: 1;
    color: #1b2031 !important; }
  textarea:-ms-input-placeholder {
    color: #1b2031 !important; }
  textarea + label {
    margin-bottom: 7px; }
  textarea:focus {
    box-shadow: none !important;
    border-color: #cdd3e0 !important; }
  textarea.wpcf7-not-valid {
    border-color: #e40000; }
    textarea.wpcf7-not-valid + label {
      color: #e40000; }

select {
  display: inline-block;
  border: 1px solid gray;
  padding: 5px 20px;
  line-height: 30px;
  font-size: 16px;
  height: 42px;
  vertical-align: middle;
  background: #ffffff;
  outline: none; }

.button, .content button,
input[type="submit"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 16px;
  padding: 5px 24px;
  min-height: 46px;
  text-align: center;
  background-color: #00B2A9;
  border: 2px solid transparent;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  -webkit-appearance: none;
  transition: color .3s, background-color .3s, border-color .3s; }
  .no-touch .button:hover, .no-touch .content button:hover, .content .no-touch button:hover, .no-touch
  input[type="submit"]:hover {
    background-color: #fff;
    border-color: #00B2A9;
    color: #00B2A9; }
    .no-touch .button:hover svg path, .no-touch .content button:hover svg path, .content .no-touch button:hover svg path, .no-touch
    input[type="submit"]:hover svg path {
      transition: fill .3s linear;
      fill: #00B2A9; }
  .button.medium, .content button.medium,
  input[type="submit"].medium {
    min-height: 48px;
    padding: 5px 38px;
    line-height: 20px;
    font-size: 16px; }
  .button.large, .content button.large,
  input[type="submit"].large {
    min-height: 60px;
    padding: 5px 64px;
    line-height: 30px;
    font-size: 18px;
    border-radius: 33px; }
  .button.play-btn svg, .content button.play-btn svg,
  input[type="submit"].play-btn svg {
    height: 24px;
    width: 24px;
    margin-right: 10px; }
    .button.play-btn svg path, .content button.play-btn svg path,
    input[type="submit"].play-btn svg path {
      fill: #ffffff; }
  .no-touch .button.play-btn:hover, .no-touch .content button.play-btn:hover, .content .no-touch button.play-btn:hover, .no-touch
  input[type="submit"].play-btn:hover {
    background-color: #fff;
    border-color: #00B2A9;
    color: #00B2A9; }
    .no-touch .button.play-btn:hover svg path, .no-touch .content button.play-btn:hover svg path, .content .no-touch button.play-btn:hover svg path, .no-touch
    input[type="submit"].play-btn:hover svg path {
      fill: #00B2A9; }
  .button.white, .content button.white,
  input[type="submit"].white {
    background-color: #fff;
    color: #00B2A9; }
    .no-touch .button.white:hover, .no-touch .content button.white:hover, .content .no-touch button.white:hover, .no-touch
    input[type="submit"].white:hover {
      background-color: #00B2A9;
      color: #fff; }
    .button.white.border, .content button.white.border,
    input[type="submit"].white.border {
      border-color: #fff; }
    .button.white.border-turquoise, .content button.white.border-turquoise,
    input[type="submit"].white.border-turquoise {
      border-color: #00B2A9; }
    .button.white.revert, .content button.white.revert,
    input[type="submit"].white.revert {
      background-color: #fff;
      color: #00B2A9; }
    .button.white.transparent, .content button.white.transparent,
    input[type="submit"].white.transparent {
      background-color: transparent;
      color: #fff; }
      .no-touch .button.white.transparent:hover, .no-touch .content button.white.transparent:hover, .content .no-touch button.white.transparent:hover, .no-touch
      input[type="submit"].white.transparent:hover {
        color: #000;
        background-color: #fff; }
    .button.white.transparent-turquoise, .content button.white.transparent-turquoise,
    input[type="submit"].white.transparent-turquoise {
      background-color: transparent;
      color: #00B2A9; }
      .no-touch .button.white.transparent-turquoise:hover, .no-touch .content button.white.transparent-turquoise:hover, .content .no-touch button.white.transparent-turquoise:hover, .no-touch
      input[type="submit"].white.transparent-turquoise:hover {
        color: #00B2A9;
        background-color: #fff; }
  .button.border, .content button.border, .button.primary, .content button.primary,
  input[type="submit"].border,
  input[type="submit"].primary {
    border-color: #00B2A9; }
    .no-touch .button.border:hover, .no-touch .content button.border:hover, .content .no-touch button.border:hover, .no-touch .button.primary:hover, .no-touch .content button.primary:hover, .content .no-touch button.primary:hover, .no-touch
    input[type="submit"].border:hover, .no-touch
    input[type="submit"].primary:hover {
      color: #00B2A9;
      background-color: #fff; }
    .button.border.revert, .content button.border.revert, .button.primary.revert, .content button.primary.revert,
    input[type="submit"].border.revert,
    input[type="submit"].primary.revert {
      border-color: #00B2A9;
      color: #00B2A9;
      background-color: rgba(0, 0, 0, 0); }
      .button.border.revert svg path, .content button.border.revert svg path, .button.primary.revert svg path, .content button.primary.revert svg path,
      input[type="submit"].border.revert svg path,
      input[type="submit"].primary.revert svg path {
        fill: #00B2A9; }
      .no-touch .button.border.revert:hover, .no-touch .content button.border.revert:hover, .content .no-touch button.border.revert:hover, .no-touch .button.primary.revert:hover, .no-touch .content button.primary.revert:hover, .content .no-touch button.primary.revert:hover, .no-touch
      input[type="submit"].border.revert:hover, .no-touch
      input[type="submit"].primary.revert:hover {
        color: #fff !important;
        background-color: #00B2A9;
        border-color: #00B2A9; }
        .no-touch .button.border.revert:hover svg path, .no-touch .content button.border.revert:hover svg path, .content .no-touch button.border.revert:hover svg path, .no-touch .button.primary.revert:hover svg path, .no-touch .content button.primary.revert:hover svg path, .content .no-touch button.primary.revert:hover svg path, .no-touch
        input[type="submit"].border.revert:hover svg path, .no-touch
        input[type="submit"].primary.revert:hover svg path {
          fill: #fff; }
  .button.blue, .content button.blue,
  input[type="submit"].blue {
    background-color: #5f87ff; }
    .no-touch .button.blue:hover, .no-touch .content button.blue:hover, .content .no-touch button.blue:hover, .no-touch
    input[type="submit"].blue:hover {
      background-color: #00B2A9;
      color: #ffffff; }
  .button.gray, .content button.gray,
  input[type="submit"].gray {
    background-color: #979797; }
    .no-touch .button.gray:hover, .no-touch .content button.gray:hover, .content .no-touch button.gray:hover, .no-touch
    input[type="submit"].gray:hover {
      background-color: #00B2A9;
      color: #ffffff; }
  .button.light-gray, .content button.light-gray,
  input[type="submit"].light-gray {
    background-color: #d8d8d8;
    color: #1b2031; }
    .no-touch .button.light-gray:hover, .no-touch .content button.light-gray:hover, .content .no-touch button.light-gray:hover, .no-touch
    input[type="submit"].light-gray:hover {
      background-color: #00B2A9;
      color: #ffffff; }
  .button.purple, .content button.purple,
  input[type="submit"].purple {
    background-color: #A77BCA; }
    .no-touch .button.purple:hover, .no-touch .content button.purple:hover, .content .no-touch button.purple:hover, .no-touch
    input[type="submit"].purple:hover {
      background-color: #00B2A9;
      color: #ffffff; }
  .button.watch-video-btn, .content button.watch-video-btn,
  input[type="submit"].watch-video-btn {
    overflow: hidden;
    position: relative; }
    .button.watch-video-btn .vidyard-player-container, .content button.watch-video-btn .vidyard-player-container,
    input[type="submit"].watch-video-btn .vidyard-player-container {
      position: absolute !important;
      top: 0;
      left: 0;
      opacity: 0; }
    .button.watch-video-btn > img.vidyard-player-embed:first-child, .content button.watch-video-btn > img.vidyard-player-embed:first-child,
    input[type="submit"].watch-video-btn > img.vidyard-player-embed:first-child {
      display: none !important; }
  .button:active, .content button:active, .button:focus, .content button:focus,
  input[type="submit"]:active,
  input[type="submit"]:focus {
    color: #ffffff; }

button {
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif; }

.watch-video-web-series,
.watch-video-people,
.watch-video-product,
.watch-video {
  position: relative;
  display: inline-block;
  padding: 0 0 0 35px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase; }
  @media (min-width: 1800px) {
    .watch-video-web-series,
    .watch-video-people,
    .watch-video-product,
    .watch-video {
      font-size: 18px; } }

@media (min-width: 1200px) and (max-width: 1799px) {
  .hero .watch-video-web-series, .hero
  .watch-video-people, .hero
  .watch-video-product, .hero
  .watch-video {
    margin-right: 30px; } }
  .watch-video-web-series span,
  .watch-video-people span,
  .watch-video-product span,
  .watch-video span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 25px;
    height: 25px; }
    @media (min-width: 1800px) {
      .watch-video-web-series span,
      .watch-video-people span,
      .watch-video-product span,
      .watch-video span {
        width: 27px;
        height: 27px; } }
  .watch-video-web-series svg,
  .watch-video-people svg,
  .watch-video-product svg,
  .watch-video svg {
    width: 100%;
    height: auto;
    max-height: 100%; }

.video-block {
  position: relative; }
  .content .video-block {
    margin-top: 30px;
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .content .video-block {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .content .video-block > div {
      padding-top: 55.37%; }
  .video-block::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2; }
  .video-block > div {
    height: 0;
    padding-top: 38.02%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative; }
  .video-block .watch-video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 10; }
  .video-block .watch-video-web-series {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 10; }
  .video-block .watch-video-product {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 10; }

.features-image {
  position: relative; }
  .content .features-image {
    margin-top: 30px;
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .content .features-image {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .content .features-image > div {
      padding-top: 55.37%; }
  .features-image::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .features-image > div {
    height: 0;
    padding-top: 38.02%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative; }

.close-btn {
  position: relative;
  display: inline-block;
  min-height: auto;
  min-width: auto;
  padding: 0 35px 0 0;
  border: none;
  background-color: transparent;
  color: #1b2031;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase; }
  .no-touch .close-btn {
    transition: color .3s; }
    .no-touch .close-btn:hover {
      color: #2ab1ac !important;
      background-color: transparent; }
      .no-touch .close-btn:hover::before, .no-touch .close-btn:hover::after {
        background-color: #2ab1ac; }
  .close-btn:active, .close-btn:focus {
    color: #2ab1ac !important; }
    .close-btn:active::before, .close-btn:active::after, .close-btn:focus::before, .close-btn:focus::after {
      background-color: #2ab1ac; }
  @media (min-width: 1800px) {
    .close-btn {
      padding: 0 50px 0 0;
      font-size: 16px;
      line-height: 30px; } }
  .close-btn::before, .close-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 35px;
    height: 1px;
    background-color: #1b2031;
    transition: background-color .3s; }
    @media (min-width: 1800px) {
      .close-btn::before, .close-btn::after {
        width: 50px; } }
  .close-btn::before {
    transform: translateY(-50%) rotate(-45deg); }
  .close-btn::after {
    transform: translateY(-50%) rotate(45deg); }

.close-btn-white {
  position: relative;
  display: inline-block;
  min-height: auto;
  min-width: auto;
  padding: 0 35px 0 0;
  border: none;
  background-color: transparent;
  color: #1b2031;
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase; }
  .no-touch .close-btn-white {
    transition: color .3s; }
    .no-touch .close-btn-white:hover {
      color: #2ab1ac !important;
      background-color: transparent; }
      .no-touch .close-btn-white:hover::before, .no-touch .close-btn-white:hover::after {
        background-color: #2ab1ac; }
  .close-btn-white:active, .close-btn-white:focus {
    color: #2ab1ac !important; }
    .close-btn-white:active::before, .close-btn-white:active::after, .close-btn-white:focus::before, .close-btn-white:focus::after {
      background-color: #2ab1ac; }
  @media (min-width: 1800px) {
    .close-btn-white {
      padding: 0 50px 0 0;
      font-size: 16px;
      line-height: 30px; } }
  .close-btn-white::before, .close-btn-white::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 35px;
    height: 1px;
    background-color: #fff;
    transition: background-color .3s; }
    @media (min-width: 1800px) {
      .close-btn-white::before, .close-btn-white::after {
        width: 50px; } }
  .close-btn-white::before {
    transform: translateY(-50%) rotate(-45deg); }
  .close-btn-white::after {
    transform: translateY(-50%) rotate(45deg); }

.learn-more {
  position: relative;
  display: inline-block;
  padding-left: 31px;
  font-weight: 600;
  color: #221C35;
  font-size: 16px;
  line-height: 20px; }
  .no-touch .learn-more:hover::before {
    transform: scaleY(0); }
  .no-touch .learn-more:hover::after {
    width: 20px; }
  .no-touch .learn-more:hover span {
    margin-left: 3px; }
  .learn-more.uppercase {
    text-transform: uppercase; }
    .learn-more.uppercase span {
      top: 6px; }
    .learn-more.uppercase::after {
      top: 9px; }
    .learn-more.uppercase::before {
      top: 2px; }
  .learn-more::before, .learn-more::after {
    content: '';
    position: absolute;
    background-color: #221C35; }
  .learn-more::before {
    top: 3px;
    left: 0;
    height: 9px;
    width: 2px; }
    .no-touch .learn-more::before {
      transform-origin: bottom;
      transition: transform .2s linear; }
  .learn-more::after {
    top: 10px;
    left: 0;
    width: 18px;
    height: 2px; }
    .no-touch .learn-more::after {
      transition: width .2s linear; }
  .learn-more span {
    position: absolute;
    top: 7px;
    left: 10px;
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    border-top: 2px solid #221C35;
    border-right: 2px solid #221C35; }
    .no-touch .learn-more span {
      transition: margin-left .2s linear; }

.learn-more-menu {
  position: relative;
  display: inline-block;
  padding-left: 31px;
  font-weight: 600;
  font-size: 18px; }
  @media (min-width: 1800px) {
    .learn-more-menu {
      font-size: 20px; } }
  .no-touch .learn-more-menu:hover::before {
    transform: scaleY(0); }
  .no-touch .learn-more-menu:hover::after {
    width: 20px; }
  .no-touch .learn-more-menu:hover span {
    margin-left: 3px; }
  .learn-more-menu.uppercase {
    text-transform: uppercase;
    font-size: 18px; }
    .learn-more-menu.uppercase::before {
      top: 4px; }
      @media (min-width: 1200px) {
        .learn-more-menu.uppercase::before {
          top: 6px; } }
      @media (min-width: 1800px) {
        .learn-more-menu.uppercase::before {
          top: 7px; } }
    .learn-more-menu.uppercase::after {
      top: 11px; }
      @media (min-width: 1200px) {
        .learn-more-menu.uppercase::after {
          top: 13px; } }
      @media (min-width: 1800px) {
        .learn-more-menu.uppercase::after {
          top: 14px; } }
    .learn-more-menu.uppercase span {
      top: 8px; }
      @media (min-width: 1200px) {
        .learn-more-menu.uppercase span {
          top: 91px; } }
      @media (min-width: 1800px) {
        .learn-more-menu.uppercase span {
          top: 91px; } }
  .learn-more-menu::before, .learn-more-menu::after {
    content: '';
    position: absolute;
    background-color: currentColor; }
  .learn-more-menu::before {
    top: 5px;
    left: 0;
    height: 9px;
    width: 2px; }
    @media (min-width: 1200px) {
      .learn-more-menu::before {
        top: 65px;
        left: 210px; } }
    @media (min-width: 1800px) {
      .learn-more-menu::before {
        top: 65px; } }
    .no-touch .learn-more-menu::before {
      transform-origin: bottom;
      transition: transform .2s linear; }
  .learn-more-menu::after {
    top: 12px;
    left: 0;
    width: 18px;
    height: 2px; }
    @media (min-width: 1200px) {
      .learn-more-menu::after {
        top: 74px;
        left: 210px; } }
    @media (min-width: 1800px) {
      .learn-more-menu::after {
        top: 74px; } }
    .no-touch .learn-more-menu::after {
      transition: width .2s linear; }
  .learn-more-menu span {
    position: absolute;
    top: 9px;
    left: 10px;
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor; }
    @media (min-width: 1200px) {
      .learn-more-menu span {
        top: 71px;
        left: 220px; } }
    @media (min-width: 1800px) {
      .learn-more-menu span {
        top: 12px;
        top: 71px; } }
    .no-touch .learn-more-menu span {
      transition: margin-left .2s linear; }

.learn-more-menu:hover {
  color: #00B2A9; }

.get-started {
  display: inline-block;
  border-bottom: 2px solid #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  -webkit-appearance: none;
  /*&:hover {
        @include media(">=desktop"){
            color: rgba($white, .65);
            border-color: rgba($white, .65);
        }
    }*/ }
  @media (min-width: 1200px) {
    .get-started {
      transition: color .3s, border-color .3s;
      font-size: 16px; } }
  @media (min-width: 1800px) {
    .get-started {
      font-size: 18px; } }
  .get-started:active, .get-started:focus {
    color: inherit; }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"]:checked + label::before {
    background-color: #00B2A9;
    border-color: #00B2A9 !important; }
  input[type="checkbox"]:checked + label::after {
    opacity: 1; }
  input[type="checkbox"] + label {
    position: relative;
    padding-left: 34px;
    color: #221C35;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    cursor: pointer; }
    .no-touch input[type="checkbox"] + label:hover::before {
      border-width: 2px; }
    input[type="checkbox"] + label::before, input[type="checkbox"] + label::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 2px; }
    input[type="checkbox"] + label::before {
      border: 1px solid rgba(34, 28, 53, 0.8);
      transition: background-color .3s, border-color .3s; }
    input[type="checkbox"] + label::after {
      background-size: 10px 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3E%3Cpath fill='%23fff' transform='translate(-192 -5924)' d='M192.22 5929.279c-.673-.667.351-1.682 1.025-1.014l3.007 2.977 6.358-6.998c.636-.7 1.712.259 1.076.958l-6.895 7.588a.73.73 0 0 1-1.024 0z'/%3E%3C/svg%3E");
      transition: opacity .3s;
      opacity: 0; }

input[type="radio"] {
  display: none; }
  input[type="radio"]:checked + label::before {
    border-color: #02ACD4;
    border-width: 1px !important;
    background-color: #00B2A9; }
  input[type="radio"]:checked + label::after {
    opacity: 1; }
  input[type="radio"] + label {
    position: relative;
    padding-left: 34px;
    color: #221C35;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    cursor: pointer; }
    .no-touch input[type="radio"] + label:hover::before {
      border-width: 2px; }
    input[type="radio"] + label::before, input[type="radio"] + label::after {
      content: "";
      position: absolute;
      border-radius: 50%; }
    input[type="radio"] + label::before {
      top: 6px;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid rgba(34, 28, 53, 0.8);
      transition: border-color .3s; }
    input[type="radio"] + label::after {
      top: 13px;
      left: 7px;
      width: 10px;
      height: 10px;
      background-color: #fff;
      transition: opacity .3s;
      opacity: 0; }

label {
  display: inline-block;
  vertical-align: middle;
  color: #1b2031; }

.container,
.blog-details-container {
  width: 100%;
  padding: 0 10px;
  clear: both; }
  @media (min-width: 1200px) {
    .container,
    .blog-details-container {
      max-width: 1300px;
      margin: 0 auto; } }
  @media (min-width: 1800px) {
    .container,
    .blog-details-container {
      max-width: 1600px; } }
  @media (min-width: 1200px) {
    .container.small,
    .blog-details-container.small {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container.big,
    .blog-details-container.big {
      max-width: 1200px; } }
  @media (min-width: 1440px) {
    .container.big,
    .blog-details-container.big {
      max-width: 1420px; } }
  @media (min-width: 1800px) {
    .container.big,
    .blog-details-container.big {
      max-width: 1760px; } }
  .container.updated,
  .blog-details-container.updated {
    padding: 0 20px; }
    @media (min-width: 1200px) {
      .container.updated,
      .blog-details-container.updated {
        padding: 0 64px;
        max-width: 100%; } }
    @media (min-width: 1440px) {
      .container.updated,
      .blog-details-container.updated {
        padding: 0 80px; } }
    @media (min-width: 1800px) {
      .container.updated,
      .blog-details-container.updated {
        padding: 0 170px;
        max-width: 1920px;
        margin: 0 auto; } }

@media (min-width: 1800px) {
  .blog-details-container {
    max-width: 960px; } }

.content .text-article-title {
  text-align: center;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .content .text-article-title {
      margin-bottom: 30px; } }
  @media (min-width: 1800px) {
    .content .text-article-title {
      margin-bottom: 30px; } }

.call_to-action-webinars-hero {
  position: absolute;
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-size: 0;
  padding: 0;
  min-width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-appearance: initial !important;
  -moz-appearance: initial !important;
  appearance: initial !important; }

a.facts {
  color: #2ab1ac; }
  .no-touch a.facts:hover {
    text-decoration: underline; }

blockquote {
  border: none !important;
  position: relative;
  max-width: 100%;
  margin: 30px 0;
  padding-top: 45px; }
  @media (min-width: 1200px) {
    blockquote {
      padding-top: 60px; } }
  @media (min-width: 1800px) {
    blockquote {
      margin: 30px 0; } }
  blockquote::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
    width: 47px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 35'%3E%3Cg fill='%235f87ff' transform='translate(-682 -4248)'%3E%3Cpath d='M708 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3Cpath d='M682.04 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3C/g%3E%3C/svg%3E"); }
  blockquote q {
    font-weight: 600;
    quotes: none;
    font-size: 18px;
    line-height: 28px;
    color: #1b2031; }
    @media (min-width: 1200px) {
      blockquote q {
        font-size: 20px;
        line-height: 30px; } }
    @media (min-width: 1800px) {
      blockquote q {
        font-size: 22px;
        line-height: 32px; } }
    blockquote q:before, blockquote q:after {
      quotes: none; }
  blockquote cite {
    display: block;
    margin-top: 15px;
    color: #1b2031;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px; }
    @media (min-width: 1200px) {
      blockquote cite {
        font-size: 16px;
        line-height: 26px; } }
    @media (min-width: 1800px) {
      blockquote cite {
        margin-top: 19px;
        font-size: 18px;
        line-height: 28px; } }
    blockquote cite span {
      display: block;
      color: rgba(27, 32, 49, 0.65); }
  blockquote.fact {
    padding: 12px 0 0 30px;
    background-size: 93px 70px;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E"); }
    blockquote.fact::before {
      display: none; }
    blockquote.fact q {
      font-size: 16px;
      line-height: 26px; }
      @media (min-width: 1200px) {
        blockquote.fact q {
          font-size: 18px;
          line-height: 28px; } }
      @media (min-width: 1800px) {
        blockquote.fact q {
          font-size: 20px;
          line-height: 30px; } }
      blockquote.fact q:before, blockquote.fact q:after {
        quotes: none; }
    blockquote.fact cite {
      font-size: 12px;
      line-height: 22px; }
      @media (min-width: 1200px) {
        blockquote.fact cite {
          font-size: 14px;
          line-height: 24px; } }
      @media (min-width: 1800px) {
        blockquote.fact cite {
          font-size: 16px;
          line-height: 26px; } }
  blockquote.article-quote {
    max-width: 780px;
    margin: 0 auto;
    padding-top: 26px;
    text-align: center;
    background-position: center top;
    background-size: 120px 90px;
    min-height: 92px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E"); }
    @media (min-width: 1200px) {
      blockquote.article-quote {
        background-size: 150px 113px;
        min-height: 115px; } }
    @media (min-width: 1800px) {
      blockquote.article-quote {
        background-size: 198px 149px;
        min-height: 150px; } }
    .content blockquote.article-quote {
      margin-bottom: 30px; }
      @media (min-width: 1200px) {
        .content blockquote.article-quote {
          margin-bottom: 30px; } }
      @media (min-width: 1800px) {
        .content blockquote.article-quote {
          margin-top: 75px; } }
    @media (min-width: 1200px) {
      .blog-post-detail blockquote.article-quote {
        margin-bottom: 90px; } }
    @media (min-width: 1800px) {
      .blog-post-detail blockquote.article-quote {
        margin-top: 60px; } }
    blockquote.article-quote::before {
      display: none; }
  blockquote.solutions-quote {
    max-width: 780px;
    margin: 0 auto;
    padding-top: 26px;
    text-align: center;
    background-position: center top;
    background-size: 100px 76px;
    min-height: 92px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E"); }
    @media (min-width: 1200px) {
      blockquote.solutions-quote {
        background-size: 100px 76px;
        min-height: 100px; } }
    @media (min-width: 1800px) {
      blockquote.solutions-quote {
        background-size: 100px 76px;
        min-height: 150px; } }
    .content blockquote.solutions-quote {
      margin-bottom: 30px; }
      @media (min-width: 1200px) {
        .content blockquote.solutions-quote {
          margin-bottom: 30px; } }
      @media (min-width: 1800px) {
        .content blockquote.solutions-quote {
          margin-top: 75px; } }
    @media (min-width: 1200px) {
      .blog-post-detail blockquote.solutions-quote {
        margin-bottom: 90px; } }
    @media (min-width: 1800px) {
      .blog-post-detail blockquote.solutions-quote {
        margin-top: 60px; } }
    blockquote.solutions-quote::before {
      display: none; }
  blockquote.article-fact {
    padding-top: 0;
    padding-left: 19px; }
    blockquote.article-fact::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-image: none;
      background-color: #5f87ff; }
    blockquote.article-fact p {
      font-size: 14px;
      line-height: 24px;
      color: rgba(27, 32, 49, 0.8); }
      @media (min-width: 1200px) {
        blockquote.article-fact p {
          font-size: 16px;
          line-height: 26px; } }
      @media (min-width: 1800px) {
        blockquote.article-fact p {
          font-size: 18px;
          line-height: 28px; } }
  blockquote a {
    color: #2ab1ac; }
    .no-touch blockquote a:hover {
      text-decoration: underline; }

.content figure {
  position: relative;
  display: block;
  width: auto !important;
  margin: 10px 0 20px;
  text-align: center; }
  @media (min-width: 1800px) {
    .content figure {
      margin: 10px 0 10px; } }
  .content figure img:not([class]) {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin: 0 0 5px 0; }
    @media (min-width: 1200px) {
      .content figure img:not([class]) {
        margin-bottom: 5px; } }
  .content figure figcaption {
    font-family: "Source Sans Pro", sans-serif;
    color: rgba(27, 32, 49, 0.5);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px; }
    @media (min-width: 1200px) {
      .content figure figcaption {
        font-size: 16px;
        line-height: 24px; } }
    @media (min-width: 1800px) {
      .content figure figcaption {
        font-size: 18px;
        line-height: 32px; } }

.blog-post-detail figure {
  text-align: center; }
  @media (min-width: 1200px) {
    .blog-post-detail figure {
      /*max-width: 1440px;*/
      margin-left: calc((100vw - 940px) / -2 + 10px);
      margin-right: calc((100vw - 940px) / -2 + 10px); } }
  @media (min-width: 1440px) {
    .blog-post-detail figure {
      margin-left: -240px;
      margin-right: -240px; } }
  @media (min-width: 1200px) {
    .blog-post-detail figure img {
      margin-bottom: 17px; } }
  .blog-post-detail figure figcaption {
    text-align: center; }
    @media (min-width: 1200px) {
      .blog-post-detail figure figcaption {
        margin: 0 calc((100vw - 940px) / 2 - 10px); } }
    @media (min-width: 1440px) {
      .blog-post-detail figure figcaption {
        margin-left: 240px;
        margin-right: 240px; } }

.content table {
  font-family: "Source Sans Pro", sans-serif;
  color: #1b2031;
  font-weight: 400;
  border: none;
  border-top: 1px solid rgba(27, 32, 49, 0.25);
  text-align: left;
  margin: 30px 0; }
  @media (min-width: 1200px) {
    .content table {
      margin: 30px 0 60px; } }
  @media (min-width: 1800px) {
    .content table {
      margin-bottom: 120px; } }
  .content table tr {
    border-bottom: 1px solid rgba(27, 32, 49, 0.25); }
  .content table td, .content table th {
    padding: 5px 0 6px; }
    @media (min-width: 1200px) {
      .content table td, .content table th {
        padding: 11px 0 12px; } }
  .content table th {
    width: 34%;
    padding-right: 10px;
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    padding-left: 15px; }
    @media (min-width: 1200px) {
      .content table th {
        font-size: 18px;
        line-height: 28px; } }
    @media (min-width: 1800px) {
      .content table th {
        font-size: 20px;
        line-height: 30px; } }
  .content table td {
    font-size: 16px;
    line-height: 26px; }
    @media (min-width: 1200px) {
      .content table td {
        font-size: 20px;
        line-height: 30px; } }
    @media (min-width: 1800px) {
      .content table td {
        font-size: 22px; } }
  .content table a {
    text-decoration: underline; }
    .no-touch .content table a:hover {
      text-decoration: none; }

.content ul li a {
  color: #00B2A9; }

.content ul li a:hover {
  text-decoration: underline; }

.content-image {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  .content-image img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover; }

.sticky-parent {
  position: relative; }
  .sticky-parent .sticky-element.fixed {
    position: fixed;
    overflow: hidden;
    max-height: calc(100vh - 155px);
    margin-bottom: 0; }
    @media (min-width: 1200px) {
      .show-announcement .sticky-parent .sticky-element.fixed {
        max-height: calc(100vh - 205px); } }
  .sticky-parent .sticky-element.bottom {
    position: absolute !important;
    top: auto !important;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 0; }

.sticky-parent-online {
  position: relative; }
  .sticky-parent-online .sticky-element-online.fixed {
    position: fixed;
    overflow: hidden;
    max-height: calc(100vh - 155px);
    margin-bottom: 0; }
    @media (min-width: 1200px) {
      .show-announcement .sticky-parent-online .sticky-element-online.fixed {
        max-height: calc(100vh - 205px); } }
  .sticky-parent-online .sticky-element-online.bottom {
    position: absolute !important;
    top: auto !important;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 0; }

.has-parallax {
  position: relative; }

#vidyard-popbox {
  width: 95%;
  max-width: 1800px;
  height: auto; }

#vidyard-content-fixed #vidyard-popbox {
  height: auto !important; }

#vidyard-overlay-wrapper {
  z-index: 2000; }

@media print {
  .image-section,
  .header .announcement-bar,
  .header .wrap > *:not(.main-logo),
  .explore-links,
  .download-block,
  .report-section,
  .page-title, .button, .content button,
  .questions-widget,
  .sharing-block,
  .footer {
    display: none !important; }
  .main {
    padding-top: 0; }
    .main > *:first-child {
      margin-top: 0; }
  .header {
    position: static !important;
    height: 145px !important;
    margin-bottom: 20px !important; }
    .header .main-logo img.white {
      display: none !important; }
    .header .main-logo img.green {
      display: block !important; } }

.left75 {
  margin-left: 75px; }

.bot200 {
  padding-bottom: 200px; }

.bot100 {
  padding-bottom: 100px; }

h2 + h3 {
  margin-top: -10px !important; }

h2 + h4 {
  margin-top: -10px !important; }

h2 + h5 {
  margin-top: -10px !important; }

h2 + h6 {
  margin-top: -10px !important; }

h3 + h4 {
  margin-top: -10px !important; }

h3 + h5 {
  margin-top: -10px !important; }

h3 + h6 {
  margin-top: -10px !important; }

h4 + h5 {
  margin-top: -10px !important; }

h4 + h6 {
  margin-top: -10px !important; }

h5 + h6 {
  margin-top: -10px !important; }

span[style] {
  line-height: 28px; }

.sticky-element.fixed {
  top: 120px; }

.indent-top {
  margin-top: 80px; }
  @media (min-width: 1200px) {
    .indent-top {
      margin-top: 160px; } }
  @media (min-width: 1440px) {
    .indent-top {
      margin-top: 180px; } }
  @media (min-width: 1800px) {
    .indent-top {
      margin-top: 200px; } }

.indent-top-medium {
  margin-top: 80px; }
  @media (min-width: 1200px) {
    .indent-top-medium {
      margin-top: 100px; } }
  @media (min-width: 1800px) {
    .indent-top-medium {
      margin-top: 120px; } }

.indent-bottom {
  margin-bottom: 80px; }
  @media (min-width: 1200px) {
    .indent-bottom {
      margin-bottom: 160px; } }
  @media (min-width: 1440px) {
    .indent-bottom {
      margin-bottom: 180px; } }
  @media (min-width: 1800px) {
    .indent-bottom {
      margin-bottom: 200px; } }

.indent-bottom-medium {
  margin-bottom: 80px; }
  @media (min-width: 1200px) {
    .indent-bottom-medium {
      margin-bottom: 100px; } }
  @media (min-width: 1800px) {
    .indent-bottom-medium {
      margin-top: 120px; } }

.has-indicators {
  padding: 0 35px;
  max-width: 320px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .has-indicators {
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .has-indicators {
      max-width: 330px; } }
  @media (min-width: 1440px) {
    .has-indicators {
      padding: 0 40px; } }
  @media (min-width: 1800px) {
    .has-indicators {
      max-width: 328px; } }
  .has-indicators .indicator-item {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 48px !important;
    height: 48px;
    outline: none;
    transition: transform .3s ease .3s;
    cursor: pointer; }
    .has-indicators .indicator-item img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .has-indicators .indicator-item svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
      opacity: 0;
      transition: opacity .3s ease .3s; }
      .has-indicators .indicator-item svg circle {
        stroke: #00B2A9;
        stroke-dasharray: 151px;
        stroke-dashoffset: 151px;
        transform: rotate(-90deg) translate(-100%, 0%); }
  .has-indicators .indicator-item-img-hld {
    border: 6px solid #242F37;
    border-radius: 50%;
    overflow: hidden; }
  .has-indicators .slick-slide > div {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: transform .3s ease; }
    .no-touch .has-indicators .slick-slide > div:hover {
      transform: scale(1.05) translateY(-2px); }
  .has-indicators .slick-current .indicator-item {
    transform: scale(1.25) translateY(-10%); }
    .has-indicators .slick-current .indicator-item svg {
      opacity: 1; }
      .has-indicators .slick-current .indicator-item svg circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 7s linear .3s; }
  .no-touch .has-indicators .slick-current > div:hover {
    transform: scale(1) translateY(0); }
  .has-indicators .prev, .has-indicators .next {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    right: -1px;
    top: 39%; }
    .has-indicators .prev.slick-disabled, .has-indicators .next.slick-disabled {
      opacity: .4; }
    @media (min-width: 1800px) {
      .has-indicators .prev, .has-indicators .next {
        top: 32%; } }
  @media (min-width: 1800px) {
    .has-indicators .next {
      right: 2px; } }
  .has-indicators .prev {
    left: -8px;
    right: auto; }
    @media (min-width: 1800px) {
      .has-indicators .prev {
        left: -4px; } }
    .has-indicators .prev svg {
      transform: rotate(-180deg); }

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
----------------------------------------
6.1 THEMES 
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
	sprites locations 
	light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
	dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
	*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
	sprites locations
	light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
	dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
	*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
	sprites locations 
	light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
	dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
	*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
	sprites locations 
	light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
	dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
	*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
.mega-stuck {
  background-color: #ffffff !important; }

.mega-stuck .mega-menu-link {
  color: #000000 !important; }

.mega-menu-logo {
  width: 100px;
  height: 50px;
  display: block; }

.mega-stuck {
  background-color: #ffffff !important;
  transition: top 0.8s; }

.mega-stuck .mega-menu-link {
  color: #000000 !important; }

.mega-menu-logo {
  width: 100px;
  height: 50px;
  display: block; }

.body-container-wrapper, .main {
  padding-top: 0px !important;
  margin-top: -6px !important; }

.main-nav .search-panel {
  top: 0px !important;
  max-height: calc(100vh - 0px); }

.header .top-links {
  float: right;
  color: #fff;
  text-align: left;
  margin-right: 200px;
  margin-top: 10px; }

.reverse-colors .top-links {
  color: #666666; }

.mega-sticky a.mega-menu-link {
  color: #000 !important; }

.mega-sticky a.mega-menu-link:hover {
  color: #2AB1ac !important; }

.mega-sticky .menu-bottom-class a.mega-menu-link {
  margin-top: -5px !important;
  color: #fff !important;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  line-height: 26px !important; }

.mega-sticky .menu-bottom-class:hover a.mega-menu-link:hover {
  margin-top: -5px !important;
  color: #2ab1ac !important;
  background: #ffffff !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.active .menu-bottom-class a.mega-menu-link {
  color: #fff !important;
  background: #2ab1ac !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.active .menu-bottom-class:hover a.mega-menu-link:hover {
  color: #2ab1ac !important;
  background: #ffffff !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.sticky-wrapper #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
  visibility: visible;
  text-align: left;
  padding: 0px 36px 0px 36px; }

.mega-stuck {
  background-color: #ffffff !important; }

.top-nav-links {
  text-align: left;
  float: left;
  padding-right: 20px;
  font-size: 14px; }

.top-nav-links:hover {
  color: #2ab1ac; }

.image-width-height {
  width: 162px !important;
  padding-bottom: 32px !important;
  padding-top: 32px !important; }

.limited-boarder-1::before {
  background-color: #cccccc !important;
  height: 2px !important;
  display: block !important;
  position: relative;
  top: -32px;
  left: 32px;
  width: 94%;
  content: ""; }

.limited-boarder-2::before {
  background-color: #cccccc !important;
  height: 2px !important;
  display: block !important;
  position: relative;
  top: -32px;
  left: 32px;
  width: 90%;
  content: ""; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
  color: #2ab1ac;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff;
  color: #2AB1ac !important;
  /*border-bottom: 2px solid #2AB1ac;*/ }

.mega-stuck.menu-bottom-class a.mega-menu-link {
  width: 121px !important;
  text-align: center !important;
  color: #fff !important;
  margin-left: 20px !important; }

.highlight a.mega-menu-link {
  color: #fff; }

.new-menu-zindex {
  z-index: 999;
  background: transparent !important; }

/*
.languages {
    margin: 5px 23px 0 0;
   
}
*/
.languages a {
  font-weight: 400;
  color: #666666; }

.menu-link-color {
  color: #000 !important; }

.menu-link-color:hover {
  color: #2AB1ac !important; }

.filldark .header .toggle-search svg {
  fill: #000 !important; }

.highlight-menu {
  background-color: #fff !important; }

.top-items-postion {
  float: right;
  position: absolute;
  right: 12px;
  top: 5px; }

.active {
  background-color: #ffffff !important; }

.dark-text, .dark-text a.mega-menu-link {
  color: #000 !important; }

.dark-text:hover a.mega-menu-link:hover {
  color: #2ab1ac !important; }

.dark-text:hover {
  color: #2ab1ac !important; }

.no-click-title {
  font-weight: 900 !important;
  font-size: 16px;
  color: #000;
  padding-bottom: 8px;
  line-height: 23px;
  padding-top: 8px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item-has-children > a.mega-menu-link > span.mega-indicator:after {
  content: '\f140';
  font-family: dashicons;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 12px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(0);
  color: inherit;
  position: relative;
  background: transparent;
  height: 0px;
  width: 0px;
  right: auto;
  line-height: inherit;
  z-index: -1; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-icon-right > a.mega-menu-link:before {
  float: right;
  margin: 3px 0 0 6px; }

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
  padding: 5px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on.mega-icon-right > a.mega-menu-link:before {
  transform: rotate(180deg); }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
  bottom: 0px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
  margin-top: 2px; }

.big-heading-hover-effect {
  display: inline-block;
  color: #000;
  cursor: pointer; }

.sub {
  color: #666666;
  width: 100%;
  clear: both;
  font-size: 16px;
  padding-bottom: 16px;
  font-weight: 300; }

.big-heading-hover-effect:hover .sub2 {
  color: #2ab1ac; }

.big-heading-hover-effect:hover .sub i {
  color: #2ab1ac;
  transform: scale(1.1, 1);
  padding-left: 2px; }

.sub2 {
  font-size: 22px;
  font-weight: normal;
  color: #000; }

.big-heading-menu-link {
  font-size: 20px;
  color: #000;
  font-weight: 600; }

/*.sub i {
display: none;
}

.sub:hover i {
  display: block;
  
}*/
.mega-menu-menu-1-mobile-open {
  overflow: hidden; }

.mega-menu-menu-1-mobile-open #mega-menu-wrap-menu-1 {
  height: 120vh;
  overflow-x: hidden; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
  text-underline-offset: 5px !important; }

.search-position {
  margin-right: 95px !important;
  position: absolute;
  right: 32px;
  top: 10px; }

.mega-menu-logo {
  width: 100px !important; }

@media only screen and (min-width: 1200px) {
  .hide-google-search {
    display: none !important; }
  .mobile-button {
    display: none !important; }
  .main-logo {
    display: none; }
  .hide-google-search {
    display: none; }
  #mega-menu-wrap-menu-1 {
    z-index: 9;
    top: 30px; }
  .new-menu-zindex {
    z-index: 99999; }
  .languages {
    margin: 2px 23px 0 0; } }

@media only screen and (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
    width: 25px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 input, #mega-menu-wrap-menu-1 #mega-menu-menu-1 img {
    max-width: 40%;
    width: 80px; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-box {
    width: 30px !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle {
    height: 45px; }
  a.mega-menu-link {
    color: #000000 !important; }
  .menu-bottom-class a.mega-menu-link {
    width: 121px !important;
    text-align: center !important;
    color: #fff !important;
    margin-left: -5px !important; }
  .gsc-control-cse {
    padding: 8px 16px 16px 16px !important; }
  .main-logo {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-right: auto;
    z-index: 55;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .mega-menu-logo {
    position: fixed;
    top: 5px !important;
    left: 0px !important;
    width: 80px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    border-radius: 0;
    border: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    background: transparent !important;
    text-align: left;
    color: #000000 !important;
    font-size: 16px;
    text-underline-offset: 5px !important; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
    text-underline-offset: 5px !important; }
  .top-items-postion {
    float: right;
    position: fixed;
    right: 28px;
    top: 0px; }
  .mega-menu-bottom-class {
    background-color: transparent !important;
    color: #666666 !important;
    text-decoration: underline !important; }
  .languages .current {
    font-weight: 900 !important; }
  .languages {
    margin: 0px;
    padding: 0px 24px 0 0; }
  .languages.expand .current {
    position: relative;
    padding: 0 25px 0 0;
    background-color: transparent;
    border: 0;
    color: #000 !important;
    cursor: pointer;
    background: #fff;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff; }
  .languages .current {
    padding: 5px 20px; }
  .hover .languages .current::before {
    border-top-color: #fff;
    border-right-color: #fff; }
  .reverse-colors .languages .current::before {
    border-top-color: #fff;
    border-right-color: #fff; }
  .mega-menu-menu-1-mobile-open .reverse-colors .languages .current::before {
    border-top-color: #000;
    border-right-color: #000; }
  .languages.expand .current::before, .reverse-colors .languages.expand .current::before, .revert-colors .languages.expand .current::before, .show-searchbar .languages.expand .current::before {
    border-top-color: #000;
    border-right-color: #000; }
  /* 
.languages ul {
    left: calc(50% - 24px);
    
}
*/
  .no-touch .close-btn-white, .no-touch .languages a {
    font-weight: 900 !important; }
  .mega-menu-menu-1-mega-sticky {
    background-color: #fff !important; }
  .mega-stuck {
    background-color: transparent !important; }
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: #fff !important;
    padding: 0px 8px 0px 8px !important; }
  .header .top-links {
    display: none; }
  .reverse-colors.new-menu-zindex {
    z-index: 99;
    padding-top: 8px;
    padding-left: 8px; }
  .header {
    padding-top: 8px;
    padding-left: 8px; }
  .active {
    background-color: transparent !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
    display: block;
    height: 100vh; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
    z-index: 9999; }
  .button-center {
    text-align: center !important;
    margin: 0 auto !important;
    margin: 0 auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    width: 120px !important; }
  .dark-text .languages .current {
    color: #fff; } }

@media only screen and (max-width: 750px) {
  .top-items-postion {
    top: 4px; } }

@media only screen and (min-width: 1600px) {
  .sub2 {
    padding-top: 4px; }
  .search-position {
    margin-right: 135px !important;
    position: absolute;
    right: 24px;
    top: 10px; }
  .no-click-title {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    line-height: 23px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    font-size: 20px !important; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
    font-size: 18px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
    font-size: 18px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 150px 0px 150px; }
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 150px 0px 150px !important; }
  .mega-menu-logo {
    width: 120px !important; }
  /*.menu-bottom-class a.mega-menu-link{

 {
    width: 150px !important;
   
}

}*/
  .top-nav-links {
    padding-right: 32px;
    font-size: 18px; }
  .header .top-links {
    margin-top: 0px;
    margin-right: 330px; }
  .top-items-postion {
    right: 120px;
    top: 0px; } }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
  margin-top: 2px; }

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
  margin-top: 2px; }

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
  background: rgba(34, 34, 34, 0);
  padding: 0px 36px 0px 36px; }

/* padding */
.left-8 {
  padding-left: 8px; }

.right-8 {
  padding-right: 8px; }

.top-8 {
  padding-top: 8px !important; }

.bot-8 {
  padding-bottom: 8px !important; }

.left-16 {
  padding-left: 16px; }

.right-16 {
  padding-right: 16px; }

.top-16 {
  padding-top: 16px !important; }

.top-16-menu {
  padding-top: 16px !important; }

.mega-stuck .top-16-menu {
  padding-top: 4px !important; }

.bot-16 {
  padding-bottom: 16px !important; }

.top-24 {
  padding-top: 24px !important; }

.left-32 {
  padding-left: 32px !important; }

.right-28 {
  padding-right: 28px !important; }

.right-32 {
  padding-right: 32px !important; }

.top-32 {
  padding-top: 32px !important; }

.bot-32 {
  padding-bottom: 32px !important; }

.top-40 {
  padding-top: 28px !important; }

.mega-stuck .top-40 {
  padding-top: 20px !important; }

.left-64 {
  padding-left: 64px !important; }

.right-64 {
  padding-right: 62px; }

.top-64 {
  padding-top: 64px !important; }

.bot-64 {
  padding-bottom: 64px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 p {
  margin-bottom: 0px; }

.getstarted-line-height > .mega-menu-link {
  line-height: 26px !important; }

.hover-solutions {
  display: block; }

.hover-solution:hover p.solutions-overview {
  display: none !important; }

.sticky {
  top: -100px; }

@media only screen and (max-width: 1200px) {
  .left-64 {
    padding-left: 32px !important; }
  .bot-mobile-72 {
    padding-bottom: 50% !important; } }

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
  padding-bottom: 4px; }

.header {
  position: absolute; }

.get-started-padding {
  padding: 0px 16px 0px 16px; }

#mega-menu-wrap-menu-1.mega-sticky {
  z-index: 9999999 !important; }

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
  min-height: 100%; }

.mega-menu-menu-1-mobile-open .languages .current {
  color: #000; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
  background: rgba(51, 51, 51, 0);
  color: #2ab1ac;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff; }

.header {
  -webkit-transition: background-color 0s linear,-webkit-transform 0s linear;
  transition: background-color 0s linear,transform 0s linear;
  transition: background-color 0s linear,transform 0s linear,-webkit-transform 0s linear; }

.mega-svg {
  filter: brightness(0) invert(1); }

.mega-sticky-wrapper .mega-svg {
  filter: brightness(1) invert(0); }

.active .mega-svg {
  filter: brightness(1) invert(0); }

.mega-menu-toggle .mega-menu-logo {
  filter: brightness(0) invert(1); }

.mega-menu-open .mega-menu-logo {
  filter: brightness(1) invert(0); }

.active .mega-menu-toggle .mega-menu-logo {
  filter: brightness(1) invert(0); }

.mega-sticky-wrapper .mega-menu-toggle .mega-menu-logo {
  filter: brightness(1) invert(0); }

/** Push menu onto new line **/
.mega-stuck {
  background-color: #ffffff !important; }

.mega-stuck .mega-menu-link {
  color: #000000 !important; }

.mega-menu-logo {
  width: 100px;
  height: 50px;
  display: block; }

/** Push menu onto new line **/
.mega-stuck {
  background-color: #ffffff !important;
  transition: top 0.8s; }

.mega-stuck .mega-menu-link {
  color: #000000 !important; }

.mega-menu-logo {
  width: 100px;
  height: 50px;
  display: block; }

.body-container-wrapper, .main {
  padding-top: 0px !important;
  margin-top: -6px !important; }

.main-nav .search-panel {
  top: 0px !important;
  max-height: calc(100vh - 0px); }

.header .top-links {
  float: right;
  color: #fff;
  text-align: left;
  margin-right: 200px;
  margin-top: 10px; }

.reverse-colors .top-links {
  color: #666666; }

.mega-sticky a.mega-menu-link {
  color: #000 !important; }

.mega-sticky a.mega-menu-link:hover {
  color: #2AB1ac !important; }

.mega-sticky .menu-bottom-class a.mega-menu-link {
  margin-top: -5px !important;
  color: #fff !important;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  line-height: 26px !important; }

.mega-sticky .menu-bottom-class:hover a.mega-menu-link:hover {
  margin-top: -5px !important;
  color: #2ab1ac !important;
  background: #ffffff !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.active .menu-bottom-class a.mega-menu-link {
  color: #fff !important;
  background: #2ab1ac !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.active .menu-bottom-class:hover a.mega-menu-link:hover {
  color: #2ab1ac !important;
  background: #ffffff !important;
  font-size: 16px;
  text-transform: uppercase;
  border-color: #2ab1ac !important;
  border-style: solid !important;
  border-top-width: 2px !important;
  border-right-width: 2px !important;
  border-bottom-width: 2px !important;
  border-left-width: 2px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  line-height: 26px !important; }

.sticky-wrapper #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
  visibility: visible;
  text-align: left;
  padding: 0px 36px 0px 36px; }

.mega-stuck {
  background-color: #ffffff !important; }

.top-nav-links {
  text-align: left;
  float: left;
  padding-right: 20px;
  font-size: 14px; }

.top-nav-links:hover {
  color: #2ab1ac; }

.image-width-height {
  width: 162px !important;
  padding-bottom: 32px !important;
  padding-top: 32px !important; }

.limited-boarder-1::before {
  background-color: #cccccc !important;
  height: 2px !important;
  display: block !important;
  position: relative;
  top: -32px;
  left: 32px;
  width: 94%;
  content: ""; }

.limited-boarder-2::before {
  background-color: #cccccc !important;
  height: 2px !important;
  display: block !important;
  position: relative;
  top: -32px;
  left: 32px;
  width: 90%;
  content: ""; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
  color: #2ab1ac;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff;
  color: #2AB1ac !important;
  /*border-bottom: 2px solid #2AB1ac;*/ }

.mega-stuck.menu-bottom-class a.mega-menu-link {
  width: 121px !important;
  text-align: center !important;
  color: #fff !important;
  margin-left: 20px !important; }

.highlight a.mega-menu-link {
  color: #fff; }

.new-menu-zindex {
  z-index: 999;
  background: transparent !important; }

/*
.languages {
    margin: 5px 23px 0 0;
   
}
*/
.languages a {
  font-weight: 400;
  color: #666666; }

.menu-link-color {
  color: #000 !important; }

.menu-link-color:hover {
  color: #2AB1ac !important; }

.filldark .header .toggle-search svg {
  fill: #000 !important; }

.highlight-menu {
  background-color: #fff !important; }

.top-items-postion {
  float: right;
  position: absolute;
  right: 12px;
  top: 5px; }

.active {
  background-color: #ffffff !important; }

.dark-text, .dark-text a.mega-menu-link {
  color: #000 !important; }

.dark-text:hover a.mega-menu-link:hover {
  color: #2ab1ac !important; }

.dark-text:hover {
  color: #2ab1ac !important; }

.no-click-title {
  font-weight: 900 !important;
  font-size: 16px;
  color: #000;
  padding-bottom: 8px;
  line-height: 23px;
  padding-top: 8px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item-has-children > a.mega-menu-link > span.mega-indicator:after {
  content: '\f140';
  font-family: dashicons;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 12px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(0);
  color: inherit;
  position: relative;
  background: transparent;
  height: 0px;
  width: 0px;
  right: auto;
  line-height: inherit;
  z-index: -1; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-icon-right > a.mega-menu-link:before {
  float: right;
  margin: 3px 0 0 6px; }

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
  padding: 5px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on.mega-icon-right > a.mega-menu-link:before {
  transform: rotate(180deg); }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
  bottom: 0px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
  margin-top: 2px; }

.big-heading-hover-effect {
  display: inline-block;
  color: #000;
  cursor: pointer; }

.sub {
  color: #666666;
  width: 100%;
  clear: both;
  font-size: 16px;
  padding-bottom: 16px;
  font-weight: 300; }

.big-heading-hover-effect:hover .sub2 {
  color: #2ab1ac; }

.big-heading-hover-effect:hover .sub i {
  color: #2ab1ac;
  transform: scale(1.1, 1);
  padding-left: 2px; }

.sub2 {
  font-size: 22px;
  font-weight: normal;
  color: #000; }

.big-heading-menu-link {
  font-size: 20px;
  color: #000;
  font-weight: 600; }

/*.sub i {
display: none;
}

.sub:hover i {
  display: block;
  
}*/
.mega-menu-menu-1-mobile-open {
  overflow: hidden; }

.mega-menu-menu-1-mobile-open #mega-menu-wrap-menu-1 {
  height: 120vh;
  overflow-x: hidden; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
  text-underline-offset: 5px !important; }

.search-position {
  margin-right: 95px !important;
  position: absolute;
  right: 32px;
  top: 10px; }

.mega-menu-logo {
  width: 100px !important; }

@media only screen and (min-width: 1200px) {
  .hide-google-search {
    display: none !important; }
  .mobile-button {
    display: none !important; }
  .main-logo {
    display: none; }
  .hide-google-search {
    display: none; }
  #mega-menu-wrap-menu-1 {
    z-index: 9;
    top: 30px; }
  .new-menu-zindex {
    z-index: 99999; }
  .languages {
    margin: 2px 23px 0 0; }
  .learn-more-menu-two-line span {
    top: 101px;
    left: 220px; }
  .learn-more-menu-two-line::before {
    top: 95px;
    left: 210px; }
  .learn-more-menu-two-line::after {
    top: 104px;
    left: 210px; } }

@media only screen and (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before {
    background-color: #2ab1ac !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
    width: 25px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 input, #mega-menu-wrap-menu-1 #mega-menu-menu-1 img {
    max-width: 40%;
    width: 80px; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-box {
    width: 30px !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle {
    height: 45px; }
  a.mega-menu-link {
    color: #000000 !important; }
  .menu-bottom-class a.mega-menu-link {
    width: 121px !important;
    text-align: center !important;
    color: #fff !important;
    margin-left: -5px !important; }
  .gsc-control-cse {
    padding: 8px 16px 16px 16px !important; }
  .main-logo {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-right: auto;
    z-index: 55;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .mega-menu-logo {
    position: fixed;
    top: 5px !important;
    left: 0px !important;
    width: 80px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    border-radius: 0;
    border: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    background: transparent !important;
    text-align: left;
    color: #000000 !important;
    font-size: 16px;
    text-underline-offset: 5px !important; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
    text-underline-offset: 5px !important; }
  .top-items-postion {
    float: right;
    position: fixed;
    right: 28px;
    top: 0px; }
  .mega-menu-bottom-class {
    background-color: transparent !important;
    color: #666666 !important;
    text-decoration: underline !important; }
  .languages .current {
    font-weight: 900 !important; }
  .languages {
    margin: 0px;
    padding: 0px 24px 0 0; }
  .languages.expand .current {
    position: relative;
    padding: 0 25px 0 0;
    background-color: transparent;
    border: 0;
    color: #000 !important;
    cursor: pointer;
    background: #fff;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff; }
  .languages .current {
    padding: 5px 20px; }
  .hover .languages .current::before {
    border-top-color: #fff;
    border-right-color: #fff; }
  .reverse-colors .languages .current::before {
    border-top-color: #fff;
    border-right-color: #fff; }
  .mega-menu-menu-1-mobile-open .reverse-colors .languages .current::before {
    border-top-color: #000;
    border-right-color: #000; }
  .languages.expand .current::before, .reverse-colors .languages.expand .current::before, .revert-colors .languages.expand .current::before, .show-searchbar .languages.expand .current::before {
    border-top-color: #000;
    border-right-color: #000; }
  /* 
.languages ul {
    left: calc(50% - 24px);
    
}
*/
  .no-touch .close-btn-white, .no-touch .languages a {
    font-weight: 900 !important; }
  .mega-menu-menu-1-mega-sticky {
    background-color: #fff !important; }
  .mega-stuck {
    background-color: transparent !important; }
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: #fff !important;
    padding: 0px 8px 0px 8px !important; }
  .header .top-links {
    display: none; }
  .reverse-colors.new-menu-zindex {
    z-index: 99;
    padding-top: 8px;
    padding-left: 8px; }
  .header {
    padding-top: 8px;
    padding-left: 8px; }
  .active {
    background-color: transparent !important; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
    display: block;
    height: 100vh; }
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
    z-index: 9999; }
  .button-center {
    text-align: center !important;
    margin: 0 auto !important;
    margin: 0 auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    width: 120px !important; }
  .dark-text .languages .current {
    color: #fff; } }

@media only screen and (max-width: 750px) {
  .top-items-postion {
    top: 4px; } }

@media only screen and (min-width: 1600px) {
  .sub2 {
    padding-top: 4px; }
  .search-position {
    margin-right: 135px !important;
    position: absolute;
    right: 24px;
    top: 10px; }
  .no-click-title {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    line-height: 23px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    font-size: 20px !important; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
    font-size: 18px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
    font-size: 18px; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 150px 0px 150px; }
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 150px 0px 150px !important; }
  .mega-menu-logo {
    width: 120px !important; }
  /*.menu-bottom-class a.mega-menu-link{

 {
    width: 150px !important;
   
}

}*/
  .top-nav-links {
    padding-right: 32px;
    font-size: 18px; }
  .header .top-links {
    margin-top: 0px;
    margin-right: 330px; }
  .top-items-postion {
    right: 120px;
    top: 0px; } }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
  margin-top: 2px; }

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
  margin-top: 2px; }

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
  background: rgba(34, 34, 34, 0);
  padding: 0px 36px 0px 36px; }

/* padding */
.left-8 {
  padding-left: 8px; }

.right-8 {
  padding-right: 8px; }

.top-8 {
  padding-top: 8px !important; }

.bot-8 {
  padding-bottom: 8px !important; }

.left-16 {
  padding-left: 16px; }

.right-16 {
  padding-right: 16px; }

.top-16 {
  padding-top: 16px !important; }

.top-16-menu {
  padding-top: 16px !important; }

.mega-stuck .top-16-menu {
  padding-top: 4px !important; }

.bot-16 {
  padding-bottom: 16px !important; }

.top-24 {
  padding-top: 24px !important; }

.left-32 {
  padding-left: 32px !important; }

.right-28 {
  padding-right: 28px !important; }

.right-32 {
  padding-right: 32px !important; }

.top-32 {
  padding-top: 32px !important; }

.bot-32 {
  padding-bottom: 32px !important; }

.top-40 {
  padding-top: 28px !important; }

.mega-stuck .top-40 {
  padding-top: 20px !important; }

.left-64 {
  padding-left: 64px !important; }

.right-64 {
  padding-right: 62px; }

.top-64 {
  padding-top: 64px !important; }

.bot-64 {
  padding-bottom: 64px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 p {
  margin-bottom: 0px; }

.getstarted-line-height > .mega-menu-link {
  line-height: 26px !important; }

.hover-solutions {
  display: block; }

.hover-solution:hover p.solutions-overview {
  display: none !important; }

.sticky {
  top: -100px; }

@media only screen and (max-width: 1200px) {
  .left-64 {
    padding-left: 32px !important; }
  .bot-mobile-72 {
    padding-bottom: 50% !important; } }

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
  padding-bottom: 4px; }

.header {
  position: absolute; }

.get-started-padding {
  padding: 0px 16px 0px 16px; }

#mega-menu-wrap-menu-1.mega-sticky {
  z-index: 9999999999 !important; }

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
  min-height: 100%; }

.mega-menu-menu-1-mobile-open .languages .current {
  color: #000; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
  background: rgba(51, 51, 51, 0);
  color: #2ab1ac;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff; }

.header {
  -webkit-transition: background-color 0s linear,-webkit-transform 0s linear;
  transition: background-color 0s linear,transform 0s linear;
  transition: background-color 0s linear,transform 0s linear,-webkit-transform 0s linear; }

.mega-svg {
  filter: brightness(0) invert(1); }

.mega-sticky-wrapper .mega-svg {
  filter: brightness(1) invert(0); }

.active .mega-svg {
  filter: brightness(1) invert(0); }

.mega-menu-toggle .mega-menu-logo {
  filter: brightness(0) invert(1); }

.mega-menu-open .mega-menu-logo {
  filter: brightness(1) invert(0); }

.active .mega-menu-toggle .mega-menu-logo {
  filter: brightness(1) invert(0); }

.mega-sticky-wrapper .mega-menu-toggle .mega-menu-logo {
  filter: brightness(1) invert(0); }

.learn-more-menu-two-line {
  position: relative;
  display: inline-block;
  padding-left: 31px;
  font-weight: 600;
  font-size: 18px; }

.learn-more-menu-two-line span {
  position: absolute;
  top: 9px;
  left: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor; }

.learn-more-menu-two-line::after, .learn-more-menu-two-line::before {
  content: '';
  position: absolute;
  background-color: currentColor; }

@media (min-width: 1200px) {
  #mega-menu-wrap-menu-1 {
    top: auto !important;
    z-index: auto !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 {
    z-index: 9 !important;
    top: 30px !important; } }

#mega-menu-wrap-menu-1 .hide-google-search {
  display: none; }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1.mega-sticky {
    top: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    padding: 0 !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1.max-mega-menu {
    padding: 9px 80px 9px !important; } }

@media (min-width: 1600px) {
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1.max-mega-menu {
    padding: 9px 80px 9px !important; } }

@media (min-width: 1800px) {
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1.max-mega-menu {
    padding: 9px 0 9px !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 .mega-nav_get_started_button, #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 .mega-nav_contact_button {
    padding-top: 3px !important; } }

.no-touch #mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 li.mega-nav_contact_button > a.mega-menu-link:hover {
  border-color: #fff !important;
  background-color: #00B2A9 !important;
  color: #fff !important; }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1.mega-sticky .menu-bottom-class a.mega-menu-link {
    margin-top: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle {
    height: 65px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open .mega-toggle-block-2 .mega-toggle-animated-inner {
    transform: translate3d(0, 5px, 0) rotate(45deg) !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open .mega-toggle-block-2 .mega-toggle-animated-inner::after {
    transform: translate3d(0, -12px, 0) rotate(-90deg) !important; } }

#mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
  padding-bottom: 145px !important;
  display: flex !important;
  flex-direction: column !important; }
  @media (max-width: 1199px) {
    #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1.page-has-vigets {
      padding-bottom: 188px !important; } }
  @media (min-width: 568px) {
    #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
      padding-bottom: 85px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-blocks-left {
    order: 1;
    justify-content: center !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-blocks-right {
    order: 2; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-animated-box {
    width: 29px !important;
    height: 6px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-2 .mega-toggle-animated-inner {
    width: 20px !important;
    height: 2px !important;
    border-radius: 2px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-2 .mega-toggle-animated-inner::after, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-2 .mega-toggle-animated-inner::before {
    width: 20px !important;
    height: 2px !important;
    border-radius: 2px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-2 .mega-toggle-animated-inner::before {
    top: 6px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-2 .mega-toggle-animated-inner::after {
    top: 12px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-menu-logo {
    max-height: 32px !important;
    left: 50% !important;
    top: 10px !important;
    transform: translateX(-50%) !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    height: calc((var(--vh, 1vh) * 100) - 65px) !important;
    overflow: auto !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    padding: 7px 63px 9px !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    padding: 13px 80px 9px !important; } }

@media (min-width: 1600px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    padding: 13px 80px 9px !important; } }

@media (min-width: 1800px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    padding: 13px 0 9px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li:not(.mega-toggle-on) {
    max-height: 0 !important;
    overflow: hidden !important;
    padding-top: 0 !important; } }

#mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li.search-holder, #mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li.header-nav-copy {
  max-height: none !important;
  overflow: visible !important; }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li.search-holder::before, #mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li.header-nav-copy::before {
    top: -5px; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item > li.search-holder::before {
  opacity: 0 !important;
  visibility: visible; }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item.open-search > li:not(.mega-toggle-on) {
    max-height: 0 !important;
    overflow: hidden !important;
    padding-top: 0 !important; } }

#mega-menu-wrap-menu-1 #mega-menu-menu-1.open-item.open-search > li.header-nav-copy {
  max-height: 0 !important;
  overflow: hidden !important; }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.top-16-menu {
    padding-top: 8px !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.top-16-menu:not(.mega-nav_contact_button) {
    padding-top: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item {
    margin: 0 9px 0 0 !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item {
    margin: 0 16px 0 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item:nth-child(1) {
    margin-right: 0 !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item:nth-child(1) {
    margin-right: 4px !important; } }

@media (min-width: 1800px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item:nth-child(1) {
    margin-right: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item:nth-child(1) .mega-menu-link {
    padding-left: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.parent > a.mega-menu-link {
    font-size: 16px !important;
    font-weight: 600 !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.parent > a.mega-menu-link {
    font-size: 20px !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-icon-right > a.mega-menu-link::before {
    margin: 1px 0 2px 6px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-parent > a {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    font-family: "fabrikat bold", sans-serif !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-parent.mega-toggle-on > a.mega-menu-link {
    color: rgba(0, 0, 0, 0) !important;
    position: relative !important;
    z-index: 22; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-parent.mega-toggle-on > a.mega-menu-link::after {
    content: "< Menu";
    position: absolute;
    display: block !important;
    left: 20px;
    font-size: 22px;
    font-weight: 700;
    top: 50%;
    transform: translateY(-50%);
    color: #221C35; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-parent.mega-toggle-on > .mega-sub-menu {
    margin-top: -40px !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-parent > .mega-sub-menu {
    margin-top: 12px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a.mega-menu-link::before {
    transform: translateY(-5%) rotate(-90deg); } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a.mega-menu-link {
    transition: none !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
    padding-bottom: 3px; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a .mega-menu-logo {
    max-width: 72px !important;
    width: 100% !important;
    height: auto !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a .mega-menu-logo {
    max-width: 88px !important; } }

@media (min-width: 1800px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item > a .mega-menu-logo {
    max-width: 100px !important; } }

@media (min-width: 1440px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-nav_get_started_button > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-nav_contact_button > a.mega-menu-link {
    font-size: 16px !important; } }

@media (min-width: 1201px) {
  .no-touch #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-nav_get_started_button > a.mega-menu-link:hover {
    color: #00B2A9 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-nav_get_started_button {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-nav_get_started_button > a.mega-menu-link {
    height: 46px !important;
    border-radius: 24px !important;
    margin-left: 10px !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-megamenu > ul.mega-sub-menu {
    padding: 20px !important;
    background-color: #fff !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-link {
    font-size: 18px !important;
    line-height: 28px !important;
    color: #221C35 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li#mega-menu-item-34716 > a.mega-menu-link {
    padding-top: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 li .mega-menu-column.left-32 {
    padding-left: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_get_started_button, #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_contact_button {
    padding-top: 9px !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_get_started_button a, #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_contact_button a {
    padding: 0 22px 0 !important;
    display: inline-flex !important;
    align-items: center !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_contact_button {
    padding-right: 0 !important; } }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_contact_button > a.mega-menu-link {
    height: 46px !important;
    border-radius: 24px !important;
    margin-left: 10px !important;
    border: 2px solid #ffffff !important; } }

.no-touch #mega-menu-wrap-menu-1 #mega-menu-menu-1 .mega-nav_contact_button > a.mega-menu-link:hover {
  border-color: #00B2A9 !important; }

#mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile {
  height: auto !important;
  display: flex !important;
  padding: 10px 20px 24px !important;
  flex-direction: column-reverse !important;
  z-index: 2; }
  @media (max-width: 1199px) {
    #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile.page-has-vigets {
      padding: 10px 20px 76px !important; } }
  @media (min-width: 568px) {
    #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile {
      flex-direction: row-reverse !important;
      justify-content: center;
      column-gap: 20px; } }
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile a {
    height: 48px !important;
    border-radius: 30px !important;
    background-color: #00B2A9 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 568px) {
      #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile a {
        padding: 0 40px !important; } }
    #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile a:first-child {
      margin-right: 0 !important;
      margin-top: 10px !important;
      background-color: #fff !important;
      color: #221C35 !important;
      text-decoration: none !important;
      border: 2px solid #221C35 !important; }
      @media (min-width: 568px) {
        #mega-menu-wrap-menu-1 #mega-menu-menu-1 .center-bottom-mobile a:first-child {
          margin-top: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-menu-1 input {
    height: 38px !important;
    max-width: 90% !important;
    padding-left: 10px !important; } }

@media (min-width: 1800px) {
  #mega-menu-wrap-menu-1 .mega-menu {
    max-width: calc(1920px - 340px) !important;
    margin: 0 auto !important;
    width: 82.3vw !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-sub-menu .left-64 {
    padding-left: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-sub-menu .bot-32 {
    padding-left: 0 !important; } }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 .mega-sub-menu .bot-mobile-72 {
    padding-bottom: 0 !important; } }

#mega-menu-wrap-menu-1 .header-nav-copy {
  padding: 16px 20px 0 !important;
  display: flex !important;
  flex-direction: column;
  position: relative;
  margin-bottom: -3px !important; }
  #mega-menu-wrap-menu-1 .header-nav-copy::before {
    content: '';
    width: calc(100% - 40px);
    height: 1px;
    background-color: rgba(34, 28, 53, 0.1);
    position: absolute;
    top: 10px;
    left: 20px;
    display: block !important; }
  @media (min-width: 1201px) {
    #mega-menu-wrap-menu-1 .header-nav-copy {
      display: none !important;
      padding: 0 !important;
      overflow: hidden !important;
      opacity: 0 !important; } }
  #mega-menu-wrap-menu-1 .header-nav-copy a {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #221C35;
    margin-top: 8px; }
    #mega-menu-wrap-menu-1 .header-nav-copy a:first-child {
      margin-top: 8px; }

#mega-menu-wrap-menu-1 .gsc-input-box {
  border-radius: 6px;
  border-color: rgba(27, 32, 49, 0.1); }

#mega-menu-wrap-menu-1 .gsc-search-button-v2 {
  padding: 8px 1px 8px 8px !important;
  background-color: #fff !important;
  border-color: #fff !important; }
  #mega-menu-wrap-menu-1 .gsc-search-button-v2 svg {
    fill: #221C35 !important;
    width: 20px;
    height: 20px; }

#mega-menu-wrap-menu-1 table.gsc-search-box td.gsc-input {
  padding-right: 4px !important; }

@media (max-width: 1200px) {
  #mega-menu-wrap-menu-1 #mega-menu-item-34511 {
    order: 1; } }

#mega-menu-wrap-menu-1 #mega-menu-item-34511::before {
  content: '';
  display: block !important;
  width: calc(100% - 40px);
  height: 1px;
  background-color: rgba(34, 28, 53, 0.1);
  position: absolute;
  left: 20px;
  bottom: -6px; }

#mega-menu-wrap-menu-1 #mega-menu-item-34511 > button {
  display: none; }
  @media (max-width: 1200px) {
    #mega-menu-wrap-menu-1 #mega-menu-item-34511 > button {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #221C35;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      position: relative;
      margin-top: 12px;
      margin-bottom: 7px;
      padding: 0 20px;
      text-align: left; } }
  #mega-menu-wrap-menu-1 #mega-menu-item-34511 > button::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 18px;
    background-image: url('data:image/svg+xml,<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8529 12.2603L13.1099 12.0033C13.2358 11.8798 13.4051 11.8105 13.5815 11.8105C13.7578 11.8105 13.9271 11.8798 14.0529 12.0033L18.1669 16.1173C18.2905 16.2432 18.3597 16.4125 18.3597 16.5888C18.3597 16.7652 18.2905 16.9345 18.1669 17.0603L17.9099 17.3173C17.7841 17.4409 17.6148 17.5101 17.4384 17.5101C17.2621 17.5101 17.0928 17.4409 16.9669 17.3173L12.8529 13.2033C12.7294 13.0775 12.6602 12.9082 12.6602 12.7318C12.6602 12.5555 12.7294 12.3862 12.8529 12.2603Z" fill="%23221C35"/><path d="M14.5696 7.71408C14.5696 4.37108 11.8266 1.62907 8.48362 1.62907C5.14062 1.62907 2.3986 4.37108 2.3986 7.71408C2.3986 11.0571 5.14062 13.8001 8.48362 13.8001C11.8266 13.8001 14.5696 11.0571 14.5696 7.71408ZM16.1986 7.71408C16.203 8.72845 16.0064 9.73363 15.6202 10.6716C15.2341 11.6096 14.666 12.4619 13.9487 13.1791C13.2314 13.8964 12.3792 14.4645 11.4412 14.8507C10.5032 15.2369 9.49799 15.4334 8.48362 15.4291C7.46934 15.4333 6.46423 15.2366 5.52635 14.8504C4.58846 14.4642 3.73637 13.896 3.01921 13.1788C2.30204 12.4615 1.73399 11.6093 1.34788 10.6714C0.961774 9.73346 0.765236 8.72836 0.769602 7.71408C0.765369 6.69987 0.96202 5.69486 1.34819 4.75704C1.73435 3.81923 2.30239 2.96717 3.01954 2.25001C3.7367 1.53285 4.58877 0.964804 5.52659 0.578639C6.46441 0.192473 7.46942 -0.00416244 8.48362 7.11756e-05C9.49791 -0.00429483 10.503 0.192243 11.4409 0.578349C12.3789 0.964455 13.2311 1.53249 13.9483 2.24966C14.6656 2.96683 15.2337 3.81893 15.6199 4.75682C16.0062 5.6947 16.2029 6.69979 16.1986 7.71408Z" fill="%23221C35"/></svg>');
    background-size: contain;
    margin-right: 15px;
    margin-bottom: -3px; }
    @media (min-width: 1201px) {
      #mega-menu-wrap-menu-1 #mega-menu-item-34511 > button::before {
        display: none !important; } }

#mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on {
  display: flex !important;
  flex-direction: column-reverse !important; }
  #mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on::before {
    display: none !important; }
  #mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on > button {
    color: rgba(0, 0, 0, 0) !important; }
    @media (max-width: 1200px) {
      #mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on > button::after {
        content: "< Menu";
        position: absolute;
        display: block !important;
        left: 20px;
        font-size: 22px;
        font-weight: 700;
        top: 50%;
        transform: translateY(-50%);
        color: #221C35; } }
    #mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on > button::before {
      display: none; }
  #mega-menu-wrap-menu-1 #mega-menu-item-34511.mega-toggle-on .hide-google-search {
    display: block; }

@media (min-width: 1201px) {
  #mega-menu-wrap-menu-1.active #mega-menu-menu-1 .mega-nav_contact_button > a.mega-menu-link {
    color: #00B2A9 !important;
    border-color: #00B2A9 !important; } }

.no-touch #mega-menu-wrap-menu-1.active #mega-menu-menu-1 .mega-nav_contact_button > a.mega-menu-link:hover {
  border-color: #00837C !important;
  color: #00837C !important; }

@media (max-width: 1200px) {
  .mega-menu-menu-1-mobile-open #mega-menu-wrap-menu-1 {
    height: calc(var(--vh, 1vh) * 100) !important;
    overflow: hidden !important; } }
