#mega-menu-wrap-menu-1 {

    @include media('>=desktop') {
        top: auto !important;
        z-index: auto !important;
    }

    @include media('>desktop') {
        z-index: 9 !important;
        top: 30px !important;
    }

    .hide-google-search {
        display: none;
    }

    &.mega-sticky {
        @include media('>desktop') {
            top: 0 !important;
        }

        #mega-menu-menu-1 {
            @include media('<=desktop') {
                padding: 0 !important;
            }

            &.max-mega-menu {
                @include media('>=medium-desktop') {
                    padding: 9px 80px 9px!important;
                }

                @include media('>=1600px') {
                    padding: 9px 80px 9px!important;
                }

                @include media('>=large-desktop') {
                    padding: 9px 0 9px !important;
                }
            }

            .mega-nav_get_started_button, .mega-nav_contact_button {
                @include media('>desktop') {
                    padding-top: 3px!important;
                }
            }

            li {

               &.mega-nav_contact_button {

                    & > a {
                        &.mega-menu-link {

                            .no-touch & {
                                &:hover {
                                    border-color: $white-origin !important;
                                    background-color: $percian-green !important;
                                    color: $white-origin !important;
                                }
                            }
                        }
                    }
                }
            }

            
        }

        .menu-bottom-class {

            a {
                &.mega-menu-link {
                    @include media('>desktop') {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }

    .mega-menu-toggle {
        @include media('<=desktop') {
            height: 65px !important;
        }

        &.mega-menu-open {
            .mega-toggle-block-2 {

                .mega-toggle-animated-inner {
                    @include media('<=desktop') {
                        transform: translate3d(0,5px,0) rotate(45deg) !important;
                    }

                    &::after {
                        @include media('<=desktop') {
                            transform: translate3d(0,-12px,0) rotate(-90deg) !important;
                        }
                    }
                }
            }

            & + #mega-menu-menu-1 {
                padding-bottom: 145px !important;
                display: flex !important;
                flex-direction: column !important;

                &.page-has-vigets {
                    @include media('<desktop') {
                        padding-bottom: 188px!important;
                    }
                }

                @include media('>=mobile-landscape') {
                    padding-bottom: 85px !important;
                }
            }
        }

        .mega-toggle-blocks-left {
            @include media('<=desktop') {
                order: 1;
                justify-content: center !important;
            }
        }

        .mega-toggle-blocks-right {
            @include media('<=desktop') {
                order: 2;
            }
        }

        .mega-toggle-animated-box {
            @include media('<=desktop') {
                width: 29px !important;
                height: 6px !important;
            }
        }

        .mega-toggle-block-2 {

            .mega-toggle-animated-inner {
                @include media('<=desktop') {
                    width: 20px !important;
                    height: 2px !important;
                    border-radius: 2px !important;
                }

                &::after, &::before {
                    @include media('<=desktop') {
                        width: 20px !important;
                        height: 2px !important;
                        border-radius: 2px !important;
                    }
                }

                &::before { 
                    @include media('<=desktop') {
                        top: 6px !important;
                    }
                }

                &::after {
                    @include media('<=desktop') {
                        top: 12px !important;
                    }
                }
            }
        }
    }

    .mega-menu-logo {
        @include media('<=desktop') {
            max-height: 32px !important;
            left: 50% !important;
            top: 10px!important;
            transform: translateX(-50%) !important;
        }
    }

    #mega-menu-menu-1 {
        @include media('<=desktop') {
            height: calc((var(--vh, 1vh) * 100) - 65px) !important;
            // height: calc(100vh - 65px) !important;
            overflow: auto !important;
        }

        @include media('>desktop') {
            padding: 7px 63px 9px !important;
        }

        @include media('>=medium-desktop') {
            padding: 13px 80px 9px!important;
        }

        @include media('>=1600px') {
            padding: 13px 80px 9px!important;
        }

        @include media('>=large-desktop') {
            padding: 13px 0 9px !important;
        }

        &.open-item {

            & > li {
                &:not(.mega-toggle-on) {
                    @include media('<=desktop') {
                        max-height: 0 !important;
                        overflow: hidden !important;
                        padding-top: 0 !important;
                    }
                }

                &.search-holder, &.header-nav-copy {
                    max-height: none !important;
                    overflow: visible !important;

                    &::before {
                        top: -5px;
                    }
                }

                &.search-holder {
                    &::before {
                        opacity: 0 !important;
                        visibility: visible;
                    }
                }
            }

            &.open-search {
                & > li {
                    &:not(.mega-toggle-on) {
                        @include media('<=desktop') {
                            max-height: 0 !important;
                            overflow: hidden !important;
                            padding-top: 0 !important;
                        }
                    }

                    &.header-nav-copy {
                        max-height: 0 !important;
                        overflow: hidden !important;
                    }
                }
            }
        }

        li {

            &.top-16-menu {
                @include media('<=desktop') {
                    padding-top: 8px!important;
                }

                &:not(.mega-nav_contact_button) {
                    @include media('>desktop') {
                        padding-top: 0 !important;
                    }
                }
            }

            &.mega-menu-item {

                @include media('>desktop') {
                    margin: 0 9px 0 0 !important;
                }

                @include media('>=medium-desktop') {
                    margin: 0 16px 0 0 !important;
                }

                &:nth-child(1) {
                    @include media('>desktop') {
                        margin-right: 0 !important;
                    }

                    @include media('>=medium-desktop') {
                        margin-right: 4px !important;
                    }

                    @include media('>=large-desktop') {
                        margin-right: 0 !important;
                    }

                    .mega-menu-link {
                        @include media('>desktop') {
                            padding-left: 0 !important;
                        }
                    }
                }

                &.parent {

                    & > a {
                        &.mega-menu-link {

                            @include media('>desktop') {
                                font-size: 16px !important;
                                font-weight: $semibold !important;
                            }

                            @include media('>=medium-desktop') {
                                font-size: 20px !important;
                            }
                        }
                    }
                }

                &.mega-icon-right {

                    & > a {
                        &.mega-menu-link {
                            &::before {
                                @include media('>desktop') {
                                    margin: 1px 0 2px 6px !important;
                                }
                            }
                        }
                    }
                }

                &.mega-parent {
                    & > a {
                        @include media('<=desktop') {
                            height: 30px !important;
                            line-height: 30px !important;
                            font-size: 22px !important;
                            font-weight: 700 !important;
                            font-family: $fabrikat !important;
                        }
                    }

                    &.mega-toggle-on {

                        & > a {

                            &.mega-menu-link {

                                @include media('<=desktop') {
                                    color: rgba(0,0,0,0) !important;
                                    position: relative !important;
                                    z-index: 22;
                                }

                                &::after {
                                    @include media('<=desktop') {
                                        content: "< Menu";
                                        position: absolute;
                                        display: block !important;
                                        left: 20px;
                                        font-size: 22px;
                                        font-weight: $databold;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        color: $haiti-blue;
                                    }
                                }
                            }
                        }

                        & > .mega-sub-menu {
                            @include media('<=desktop') {
                                margin-top: -40px !important;
                            }
                        }
                    }

                    & > .mega-sub-menu {
                        @include media('>desktop') {
                            margin-top: 12px !important;
                        }
                    }
                }

                & > a {

                    &.mega-menu-link {
                        &::before {
                            @include media('<=desktop') {
                                transform: translateY(-5%)rotate(-90deg);
                            }
                        }

                        @include media('<=desktop') {
                            transition: none !important;
                        }
                    }

                    &.mega-menu-logo {
                        & > img {

                            &.mega-menu-logo {
                                @include media('>desktop') {
                                    padding-bottom: 3px;
                                }
                            }
                        }
                    }

                    .mega-menu-logo {
                        @include media('>desktop') {
                            max-width: 72px !important;
                            width: 100% !important;
                            height: auto !important;
                        }

                        @include media('>=medium-desktop') {
                            max-width: 88px !important;
                        }

                        @include media('>=large-desktop') {
                            max-width: 100px !important;
                        }
                    }
                }

                &.mega-nav_get_started_button, &.mega-nav_contact_button {

                    & > a {
                        &.mega-menu-link {
                            @include media('>=medium-desktop') {
                                font-size: 16px !important;
                            }
                        }
                    }
                }

                &.mega-nav_get_started_button {

                    & > a {
                        &.mega-menu-link {

                            .no-touch & {
                                &:hover {
                                    @include media('>desktop') {
                                        color: $percian-green !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.mega-nav_get_started_button {
                @include media('>desktop') {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }

                & > a {
                    &.mega-menu-link {
                        @include media('>desktop') {
                            height: 46px !important;
                            border-radius: 24px !important;
                            margin-left: 10px !important;
                        }
                    }
                }
            }

            &.mega-menu-megamenu {

                & > ul {

                    &.mega-sub-menu {
                        @include media('<=desktop') {
                            padding: 20px !important;
                            background-color: #fff !important;
                        }

                        .mega-menu-link {
                            @include media('<=desktop') {
                                font-size: 18px !important;
                                line-height: 28px !important;
                                color: $haiti-blue !important;
                            }
                        }
                    }
                }
            }

            &#mega-menu-item-34716 {
                & > a {
                    &.mega-menu-link {
                        @include media('<=desktop') {
                            padding-top: 0 !important;
                        }
                    }
                }
            }

            .mega-menu-column {

                &.left-32 {
                    @include media('<=desktop') {
                        padding-left: 0 !important;
                    }
                }
            }
        }

        .mega-nav_get_started_button, .mega-nav_contact_button {
            @include media('>desktop') {
                padding-top: 9px!important;
            }

            a {
                @include media('>desktop') {
                    padding: 0 22px 0 !important;
                    display: inline-flex !important;
                    align-items: center !important;
                }
            }
        }

        .mega-nav_contact_button {
            @include media('>desktop') {
                padding-right: 0 !important;
            }

            & > a {
                &.mega-menu-link {
                    @include media('>desktop') {
                        height: 46px !important;
                        border-radius: 24px !important;
                        margin-left: 10px !important;
                        border: 2px solid $white !important;
                    }

                    .no-touch & {
                        &:hover {
                            border-color: $percian-green !important;
                        }
                    }
                }
            }
        }

        .center-bottom-mobile {
            height: auto !important;
            display: flex !important;
            padding: 10px 20px 24px !important;
            flex-direction: column-reverse !important;
            z-index: 2;

            &.page-has-vigets {
                @include media('<desktop') {
                    padding: 10px 20px 76px!important;
                }
            }

            @include media('>=mobile-landscape') {
                flex-direction: row-reverse !important;
                justify-content: center;
                column-gap: 20px;
            }

            a {
                height: 48px !important;
                border-radius: 30px !important;
                background-color: $percian-green !important;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                @include media('>=mobile-landscape') {
                    padding: 0 40px !important;
                }

                &:first-child {
                    margin-right: 0 !important;
                    margin-top: 10px !important;
                    background-color: #fff !important;
                    color: $haiti-blue !important;
                    text-decoration: none !important;
                    border: 2px solid $haiti-blue !important;

                    @include media('>=mobile-landscape') {
                        margin-top: 0 !important;
                    }
                }
            }
        }

        input {
            @include media('<=desktop') {
                height: 38px !important;
                max-width: 90% !important;
                padding-left: 10px !important;
            }
        }
    }

    .mega-menu {
        @include media('>=large-desktop') {
            max-width: calc(1920px - 340px) !important;
            margin: 0 auto !important;
            width: 82.3vw !important;
        }
    }

    .mega-sub-menu {
        .left-64 {
            @include media('<=desktop') {
                padding-left: 0 !important;
            }
        }

        .bot-32 {
            @include media('<=desktop') {
                padding-left: 0 !important;
            }
        }

        .bot-mobile-72 {
            @include media('<=desktop') {
                padding-bottom: 0 !important;
            }
        }
    }

    .header-nav-copy {
        padding: 16px 20px 0 !important;
        display: flex !important;
        flex-direction: column;
        position: relative;
        margin-bottom: -3px !important;

        &::before {
            content: '';
            width: calc(100% - 40px);
            height: 1px;
            background-color: rgba($haiti-blue, .1);
            position: absolute;
            top: 10px;
            left: 20px;
            display: block !important;
        }

        @include media('>desktop') {
            display: none !important;
            padding: 0 !important;
            overflow: hidden !important;
            opacity: 0 !important;
        }

        a {
            font-size: 18px;
            line-height: 28px;
            font-weight: $medium;
            color: $haiti-blue;
            margin-top: 8px;

            &:first-child {
                margin-top: 8px;
            }
        }
    }

    .gsc-input-box {
        border-radius: 6px;
        border-color: rgba($darkblue, .1);
    }

    .gsc-search-button-v2 {
        padding: 8px 1px 8px 8px!important;
        background-color: #fff !important;
        border-color: #fff !important;

        svg {
            fill: $haiti-blue !important;
            width: 20px;
            height: 20px;
        }
    }

    table {
        &.gsc-search-box {
            td {
                &.gsc-input {
                    padding-right: 4px !important;
                }
            }
        }
    }

    #mega-menu-item-34511 {
        @include media('<=desktop') {
            order: 1;
        }

        &::before {
            content: '';
            display: block !important;
            width: calc(100% - 40px);
            height: 1px;
            background-color: rgba($haiti-blue, .1);
            position: absolute;
            left: 20px;
            bottom: -6px;
        }

        & > button {
            display: none;

            @include media('<=desktop') {
                font-size: 18px;
                line-height: 28px;
                font-weight: $medium;
                color: $haiti-blue;
                display: inline-block;
                background-color: rgba(0,0,0,0);
                border: none;
                position: relative;
                margin-top: 12px;
                margin-bottom: 7px;
                padding: 0 20px;
                text-align: left;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 19px;
                height: 18px;
                background-image: url('data:image/svg+xml,<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8529 12.2603L13.1099 12.0033C13.2358 11.8798 13.4051 11.8105 13.5815 11.8105C13.7578 11.8105 13.9271 11.8798 14.0529 12.0033L18.1669 16.1173C18.2905 16.2432 18.3597 16.4125 18.3597 16.5888C18.3597 16.7652 18.2905 16.9345 18.1669 17.0603L17.9099 17.3173C17.7841 17.4409 17.6148 17.5101 17.4384 17.5101C17.2621 17.5101 17.0928 17.4409 16.9669 17.3173L12.8529 13.2033C12.7294 13.0775 12.6602 12.9082 12.6602 12.7318C12.6602 12.5555 12.7294 12.3862 12.8529 12.2603Z" fill="%23221C35"/><path d="M14.5696 7.71408C14.5696 4.37108 11.8266 1.62907 8.48362 1.62907C5.14062 1.62907 2.3986 4.37108 2.3986 7.71408C2.3986 11.0571 5.14062 13.8001 8.48362 13.8001C11.8266 13.8001 14.5696 11.0571 14.5696 7.71408ZM16.1986 7.71408C16.203 8.72845 16.0064 9.73363 15.6202 10.6716C15.2341 11.6096 14.666 12.4619 13.9487 13.1791C13.2314 13.8964 12.3792 14.4645 11.4412 14.8507C10.5032 15.2369 9.49799 15.4334 8.48362 15.4291C7.46934 15.4333 6.46423 15.2366 5.52635 14.8504C4.58846 14.4642 3.73637 13.896 3.01921 13.1788C2.30204 12.4615 1.73399 11.6093 1.34788 10.6714C0.961774 9.73346 0.765236 8.72836 0.769602 7.71408C0.765369 6.69987 0.96202 5.69486 1.34819 4.75704C1.73435 3.81923 2.30239 2.96717 3.01954 2.25001C3.7367 1.53285 4.58877 0.964804 5.52659 0.578639C6.46441 0.192473 7.46942 -0.00416244 8.48362 7.11756e-05C9.49791 -0.00429483 10.503 0.192243 11.4409 0.578349C12.3789 0.964455 13.2311 1.53249 13.9483 2.24966C14.6656 2.96683 15.2337 3.81893 15.6199 4.75682C16.0062 5.6947 16.2029 6.69979 16.1986 7.71408Z" fill="%23221C35"/></svg>');
                background-size: contain;
                margin-right: 15px;
                margin-bottom: -3px;

                @include media('>desktop') {
                    display: none !important;
                }
            }
        }

        &.mega-toggle-on {
            display: flex !important;
            flex-direction: column-reverse !important;

            &::before {
                display: none !important;
            }

            & > button {
                color: rgba(0,0,0,0) !important;

                &::after {
                    @include media('<=desktop') {
                        content: "< Menu";
                        position: absolute;
                        display: block !important;
                        left: 20px;
                        font-size: 22px;
                        font-weight: $databold;
                        top: 50%;
                        transform: translateY(-50%);
                        color: $haiti-blue;
                    }
                }

                &::before {
                    display: none;
                }
            }

            .hide-google-search {
                display: block;
            }
        }
    }

    &.active {
        #mega-menu-menu-1 {
            .mega-nav_contact_button {

                & > a {

                    &.mega-menu-link {
                        @include media('>desktop') {
                            color: $percian-green !important;
                            border-color: $percian-green !important;
                        }

                        .no-touch & {
                            &:hover {
                                border-color: $dark-teal !important;
                                color: $dark-teal !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mega-menu-menu-1-mobile-open {

    #mega-menu-wrap-menu-1 {
        @include media('<=desktop') {
            // height: 100vh !important;
            height: calc(var(--vh, 1vh) * 100) !important;
            overflow: hidden !important;
        }
    }
}