$fonts-path: '../fonts/';

@font-face {
    font-family: 'fabrikat';
    src: url('#{$fonts-path}fabrikat_light_2-webfont.woff2') format('woff2'),
    url('#{$fonts-path}fabrikat_light_2-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'fabrikat';
    src: url('#{$fonts-path}fabrikat_regular-webfont.woff2') format('woff2'),
    url('#{$fonts-path}fabrikat_regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'fabrikat bold';
    src: url('#{$fonts-path}fabrikat-bold.woff') format('woff');
    //font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'fabrikat bold itiliac';
    src: url('#{$fonts-path}fabrikat-bold.woff') format('woff');
    //font-weight: 400;
    font-style: normal;
    font-display: swap;
}

a.postings-link {
 text-decoration: underline;
    color: #737578;
}

