
.mega-stuck {
    background-color:#ffffff !important;
}


.mega-stuck .mega-menu-link {
    color:#000000 !important;
}

.mega-menu-logo {
    width:100px;
    height:50px;
    display:block;
}


.mega-stuck {
    background-color:#ffffff !important;
    transition: top 0.8s;
}

.mega-stuck .mega-menu-link {
    color:#000000 !important;
}

.mega-menu-logo {
    width:100px;
    height:50px;
    display:block;
}

    .body-container-wrapper, .main {
            padding-top: 0px !important;
            margin-top: -6px !important;
    }
   

    .main-nav .search-panel {
        top: 0px !important;
            max-height: calc(100vh - 0px);
    }

    .header .top-links {
        float:right; 
        color: #fff; 
        text-align:left; 
        margin-right: 200px;
        margin-top: 10px;
    }

    .reverse-colors .top-links{
        color: #666666;
    }

    .mega-sticky a.mega-menu-link {
       
         color:#000 !important;
    }

     .mega-sticky a.mega-menu-link:hover {
       
         color:#2AB1ac !important;
    }

     .mega-sticky .menu-bottom-class a.mega-menu-link {
        margin-top: -5px !important;
         color:#fff !important;
         border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    line-height: 26px !important;
    }

     .mega-sticky .menu-bottom-class:hover a.mega-menu-link:hover {
        margin-top: -5px !important;
         color:#2ab1ac !important;
         background: #ffffff !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }


.active .menu-bottom-class a.mega-menu-link {
         color:#fff !important;
         background: #2ab1ac !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }




.active .menu-bottom-class:hover a.mega-menu-link:hover {
         color:#2ab1ac !important;
         background: #ffffff !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }



.sticky-wrapper #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 36px 0px 36px;
}

   .mega-stuck {
    background-color:#ffffff !important;
   
    }



    .top-nav-links {
        text-align:left;float: left; padding-right: 20px; font-size: 14px;
    }

    .top-nav-links:hover {
        color: #2ab1ac;
    }


    .image-width-height {
        width: 162px !important;
        padding-bottom: 32px !important ;
        padding-top: 32px !important;

    }

    .top-row-nav-74{
        
    }



.limited-boarder-1::before {
    background-color: #cccccc !important;
    height: 2px !important;
    display: block !important;
    position: relative;
    top: -32px;
    left: 32px;
    width: 94%;
    content: "";
} 

.limited-boarder-2::before {
    background-color: #cccccc !important;
    height: 2px !important;
    display: block !important;
    position: relative;
    top: -32px;
    left: 32px;
    width: 90%;
    content: "";
} 


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
    color: #2ab1ac;
    font-weight: bold;
    text-decoration: none;
    border-color: #fff;
     color: #2AB1ac !important;
    /*border-bottom: 2px solid #2AB1ac;*/
    }


     .mega-stuck.menu-bottom-class a.mega-menu-link {
        width: 121px !important;
        text-align: center !important;
        color: #fff !important;
        margin-left: 20px !important;

    }

    .highlight a.mega-menu-link {
        color: #fff;
    }

.new-menu-zindex {
    z-index:999; background: transparent !important;
}

/*
.languages {
    margin: 5px 23px 0 0;
   
}
*/

.languages a {
   
    font-weight: 400;
    color: #666666;
}


.menu-link-color {
    color: #000 !important;
}

.menu-link-color:hover {
    color: #2AB1ac !important;
}

.filldark .header .toggle-search svg{
    fill: #000 !important;
}

.highlight-menu {
    background-color: #fff !important;
    
}

.top-items-postion {
    float:right; position: absolute; right: 12px; top:5px
}



.active {
    background-color: #ffffff !important;

}


.dark-text, .dark-text a.mega-menu-link {
    color: #000 !important;
}


.dark-text:hover a.mega-menu-link:hover {
    color: #2ab1ac !important;
}

.dark-text:hover {
    color: #2ab1ac !important;
}

.no-click-title {
    font-weight:900 !important;
    font-size: 16px; color:#000;
    padding-bottom:8px;
    line-height:23px;
    padding-top: 8px;
    }



#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item-has-children > a.mega-menu-link > span.mega-indicator:after {
    content: '\f140';
    font-family: dashicons;
    font-weight: normal;
    display: inline-block;
    margin: 0 0 0 12px;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(0);
    color: inherit;
    position: relative;
    background: transparent;
    height: 0px;
    width: 0px;
    right: auto;
    line-height: inherit;
    z-index: -1;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-icon-right > a.mega-menu-link:before {
    float: right;
    margin: 3px 0 0 6px;}



#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
    
    
    padding: 5px;
    }




#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on.mega-icon-right > a.mega-menu-link:before {
    transform:rotate(180deg);
}

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
    bottom: 0px;
}

#mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
    margin-top: 2px;
}


.big-heading-hover-effect{
  display:inline-block;
  color: #000;
  cursor: pointer;
}
.sub{
color: #666666;
 width: 100%;
 clear: both;
 font-size:16px; 
 padding-bottom:16px; 
 font-weight:300;
}







.big-heading-hover-effect:hover .sub2 {
 color: #2ab1ac;
}

.big-heading-hover-effect:hover .sub i {
 color: #2ab1ac;
 transform: scale(1.1, 1.0);
 padding-left: 2px;
}

.sub2{
  font-size: 22px;
  font-weight: normal;
  color: #000;
}

.big-heading-menu-link {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

/*.sub i {
display: none;
}

.sub:hover i {
  display: block;
  
}*/


    .mega-menu-menu-1-mobile-open {
        overflow: hidden;
    }

     .mega-menu-menu-1-mobile-open #mega-menu-wrap-menu-1  {
        height: 120vh;
        overflow-x: hidden;
    }

      #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
            text-underline-offset: 5px !important;
      }


.search-position {
    margin-right: 95px !important;
    position: absolute;
    right: 32px;
    top: 10px;
}

.mega-menu-logo {
    width: 100px !important;
}      


     @media only screen and (min-width: 1200px) { 


         .hide-google-search  {
    display: none !important;
}

        
        .mobile-button {
        display: none !important;
        }

        .main-logo {
            display: none;
        }

        .hide-google-search {
            display: none;
        }

        #mega-menu-wrap-menu-1 {
            z-index: 9;
            top:30px;
        }

        .new-menu-zindex {
        z-index: 99999;
        }

        .languages {
            margin: 2px 23px 0 0;
        }



    }


 @media only screen and (max-width: 1200px) {






#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner { 
        background-color: #2ab1ac !important;

}


#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after { 
        background-color: #2ab1ac !important;

}

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before { 
        background-color: #2ab1ac !important;

}


#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
        width: 25px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}




#mega-menu-wrap-menu-1 #mega-menu-menu-1 input, #mega-menu-wrap-menu-1 #mega-menu-menu-1 img {
    max-width: 40%;
    width: 80px;
    }

    #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-box {
        width: 30px !important;
    }


#mega-menu-wrap-menu-1 .mega-menu-toggle {
   
    height: 45px;
    
}


a.mega-menu-link {
        color: #000000 !important;
        }

         .menu-bottom-class a.mega-menu-link {
        width: 121px !important;
        text-align: center !important;
        color: #fff !important;
        margin-left: -5px !important;

    }

    .gsc-control-cse {
    padding: 8px 16px 16px 16px !important;
}



.main-logo {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-right: auto;
    z-index: 55;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    
}


        .mega-menu-logo {
   position: fixed ;
        top: 5px !important;
        left: 0px !important;
        width: 80px;
}


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link 
    {
    border-radius: 0;
    border: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    background: transparent !important;
    text-align: left;
    color: #000000 !important;
    font-size: 16px;
     text-underline-offset: 5px !important;

    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
        text-underline-offset: 5px !important;
    }


     .top-items-postion {
    float: right;
    position: fixed;
    right: 28px;
    top: 0px;

    }
    .mega-menu-bottom-class {
    background-color: transparent !important;
    color: #666666 !important;
    text-decoration: underline !important;
    }


.languages .current {
    font-weight: 900 !important;
}


.languages {
    margin: 0px;
   padding:  0px 24px 0 0;
    
}




.languages.expand .current {
    position: relative;
    padding: 0 25px 0 0;
    background-color: transparent;
    border: 0;
    color: #000 !important;
    cursor: pointer;
    background: #fff;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
}

.languages .current {
    padding: 5px 20px;
}

.hover .languages .current::before {
    border-top-color: #fff; 
     border-right-color: #fff;
}

.reverse-colors .languages .current::before {
     border-top-color: #fff; 
     border-right-color: #fff;
}


.mega-menu-menu-1-mobile-open .reverse-colors .languages .current::before {
     border-top-color: #000; 
     border-right-color: #000;
}

.languages.expand .current::before, .reverse-colors .languages.expand .current::before, .revert-colors .languages.expand .current::before, .show-searchbar .languages.expand .current::before {
    border-top-color: #000; 
     border-right-color: #000;
}

 /* 
.languages ul {
    left: calc(50% - 24px);
    
}
*/




.no-touch .close-btn-white, .no-touch .languages a {
    font-weight: 900 !important;
}

.mega-menu-menu-1-mega-sticky {
    background-color: #fff !important;
}

.mega-stuck {
    background-color: transparent !important;
}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: #fff !important;
    padding: 0px 8px 0px 8px !important;
}










.header .top-links {
    display: none;
}

.reverse-colors.new-menu-zindex {
    z-index: 99;
   
    padding-top: 8px;
    padding-left: 8px;
}
.header {
    
    padding-top: 8px;
    padding-left: 8px;
}


    .active {
        background-color: transparent !important;
    }


   #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
    display: block;
    height: 100vh;

}

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {

    z-index: 9999;
}


.button-center {
    text-align: center !important;
    margin: 0 auto !important;
    margin: 0 auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    width: 120px !important;
}

.dark-text .languages .current {
    color: #fff ;
}



}

@media only screen and (max-width: 750px) 

{

   .top-items-postion {
    
    top: 4px;

    }
}


@media only screen and (min-width: 1600px) 

{


.sub2{
  padding-top: 4px;
}


.search-position {
    margin-right: 135px !important;
    position: absolute;
    right: 24px;
    top: 10px;
} 


.no-click-title {
    font-weight:600;
    font-size: 18px; color:#000;
  
    line-height:23px;

    }


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    font-size: 20px !important;
    }


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
        font-size: 18px;
    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
        font-size: 18px;
    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 150px 0px 150px;


}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 150px 0px 150px !important;
}


.mega-menu-logo {
    width: 120px !important;
}


/*.menu-bottom-class a.mega-menu-link{

 {
    width: 150px !important;
   
}

}*/

.top-nav-links {
    padding-right: 32px;
    font-size: 18px;
}

.header .top-links {
    margin-top: 0px;
    margin-right: 330px;
    
}

.top-items-postion {
    right: 120px;
    top: 0px;
}



}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
    margin-top: 2px;
}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
    margin-top: 2px;
}


#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 36px 0px 36px;
}


/* padding */
.left-8 {
    padding-left: 8px;
}

.right-8 {
    padding-right: 8px;
}

.top-8 {
    padding-top: 8px !important;
}

.bot-8 {
    padding-bottom: 8px !important;
}


.left-16 {
    padding-left: 16px;
}

.right-16 {
    padding-right: 16px;
}

.top-16 {
    padding-top: 16px !important;
}

.top-16-menu {
    padding-top: 16px !important;
}

.mega-stuck .top-16-menu {
   padding-top: 4px !important;
}



.bot-16 {
    padding-bottom: 16px !important;
}



.top-24 {
    padding-top: 24px !important;
}

.left-32 {
    padding-left: 32px !important;
}


.right-28 {
    padding-right: 28px !important;
}

.right-32 {
    padding-right: 32px !important;
}

.top-32 {
    padding-top: 32px !important;
}

.bot-32 {
    padding-bottom: 32px !important;
}


.top-40 {
    padding-top: 28px !important;
}

.mega-stuck .top-40 {
     padding-top: 20px !important;
}

.left-64 {
    padding-left: 64px !important;

}



.right-64  {
    padding-right: 62px ;
}

.top-64  {
    padding-top: 64px !important;
}

.bot-64  {
    padding-bottom: 64px;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 p {
    margin-bottom: 0px ;
}

.getstarted-line-height > .mega-menu-link{
   line-height: 26px !important;
}

.hover-solutions {
    display: block;
}


.hover-solution:hover p.solutions-overview {
  display: none !important;
}


.sticky {
    top:-100px;
}

@media only screen and (max-width: 1200px) { 

    .left-64 {
    padding-left: 32px !important;

}

.bot-mobile-72 {
    padding-bottom: 50% !important;
}

}





#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
    padding-bottom: 4px;
}

.header {
    position: absolute;

}



.get-started-padding {
        padding: 0px 16px 0px 16px;
}

#mega-menu-wrap-menu-1.mega-sticky {
    z-index: 9999999 !important;
}

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
    min-height: 100%;
}


.mega-menu-menu-1-mobile-open .languages .current {
    color: #000;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
    background: rgba(51, 51, 51, 0);
    color: #2ab1ac;
    font-weight: bold;
    text-decoration: none;
    border-color: #fff;
}


  .header {
    -webkit-transition: background-color 0s linear,-webkit-transform 0s linear;
    transition: background-color 0s linear,transform 0s linear;
    transition: background-color 0s linear,transform 0s linear,-webkit-transform 0s linear;
}


  .mega-svg {
        filter: brightness(0) invert(1);
    }

     .mega-sticky-wrapper .mega-svg {
        filter: brightness(1) invert(0);
    }

.active .mega-svg {
        filter: brightness(1) invert(0);
    }

.mega-menu-toggle  .mega-menu-logo {
        filter: brightness(0) invert(1);
    }
.mega-menu-open .mega-menu-logo {
        filter: brightness(1) invert(0);
    }

.active .mega-menu-toggle  .mega-menu-logo {
       filter: brightness(1) invert(0);
    }

.mega-sticky-wrapper .mega-menu-toggle  .mega-menu-logo {
       filter: brightness(1) invert(0);
    }


    /** Push menu onto new line **/ 
// #{$wrap} { 
//     clear: both; 
// }

.mega-stuck {
    background-color:#ffffff !important;
}


.mega-stuck .mega-menu-link {
    color:#000000 !important;
}

.mega-menu-logo {
    width:100px;
    height:50px;
    display:block;
}


/** Push menu onto new line **/ 
// #{$wrap} { 
//     clear: both; 
// }

.mega-stuck {
    background-color:#ffffff !important;
    transition: top 0.8s;
}




.mega-stuck .mega-menu-link {
    color:#000000 !important;
}

.mega-menu-logo {
    width:100px;
    height:50px;
    display:block;
}

    .body-container-wrapper, .main {
            padding-top: 0px !important;
            margin-top: -6px !important;
    }
   

    .main-nav .search-panel {
        top: 0px !important;
            max-height: calc(100vh - 0px);
    }

    .header .top-links {
        float:right; 
        color: #fff; 
        text-align:left; 
        margin-right: 200px;
        margin-top: 10px;
    }

    .reverse-colors .top-links{
        color: #666666;
    }

    .mega-sticky a.mega-menu-link {
       
         color:#000 !important;
    }

     .mega-sticky a.mega-menu-link:hover {
       
         color:#2AB1ac !important;
    }

     .mega-sticky .menu-bottom-class a.mega-menu-link {
        margin-top: -5px !important;
         color:#fff !important;
         border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    line-height: 26px !important;
    }

     .mega-sticky .menu-bottom-class:hover a.mega-menu-link:hover {
        margin-top: -5px !important;
         color:#2ab1ac !important;
         background: #ffffff !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }


.active .menu-bottom-class a.mega-menu-link {
         color:#fff !important;
         background: #2ab1ac !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }




.active .menu-bottom-class:hover a.mega-menu-link:hover {
         color:#2ab1ac !important;
         background: #ffffff !important;
    font-size: 16px;
    text-transform: uppercase;
    border-color: #2ab1ac !important;
    border-style: solid !important;
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    line-height: 26px !important;
    }



.sticky-wrapper #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 36px 0px 36px;
}

   .mega-stuck {
    background-color:#ffffff !important;
   
    }



    .top-nav-links {
        text-align:left;float: left; padding-right: 20px; font-size: 14px;
    }

    .top-nav-links:hover {
        color: #2ab1ac;
    }


    .image-width-height {
        width: 162px !important;
        padding-bottom: 32px !important ;
        padding-top: 32px !important;

    }

    .top-row-nav-74{
        
    }



.limited-boarder-1::before {
    background-color: #cccccc !important;
    height: 2px !important;
    display: block !important;
    position: relative;
    top: -32px;
    left: 32px;
    width: 94%;
    content: "";
} 

.limited-boarder-2::before {
    background-color: #cccccc !important;
    height: 2px !important;
    display: block !important;
    position: relative;
    top: -32px;
    left: 32px;
    width: 90%;
    content: "";
} 


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
    color: #2ab1ac;
    font-weight: bold;
    text-decoration: none;
    border-color: #fff;
     color: #2AB1ac !important;
    /*border-bottom: 2px solid #2AB1ac;*/
    }


     .mega-stuck.menu-bottom-class a.mega-menu-link {
        width: 121px !important;
        text-align: center !important;
        color: #fff !important;
        margin-left: 20px !important;

    }

    .highlight a.mega-menu-link {
        color: #fff;
    }

.new-menu-zindex {
    z-index:999; background: transparent !important;
}

/*
.languages {
    margin: 5px 23px 0 0;
   
}
*/

.languages a {
   
    font-weight: 400;
    color: #666666;
}


.menu-link-color {
    color: #000 !important;
}

.menu-link-color:hover {
    color: #2AB1ac !important;
}

.filldark .header .toggle-search svg{
    fill: #000 !important;
}

.highlight-menu {
    background-color: #fff !important;
    
}

.top-items-postion {
    float:right; position: absolute; right: 12px; top:5px
}



.active {
    background-color: #ffffff !important;

}


.dark-text, .dark-text a.mega-menu-link {
    color: #000 !important;
}


.dark-text:hover a.mega-menu-link:hover {
    color: #2ab1ac !important;
}

.dark-text:hover {
    color: #2ab1ac !important;
}

.no-click-title {
    font-weight:900 !important;
    font-size: 16px; color:#000;
    padding-bottom:8px;
    line-height:23px;
    padding-top: 8px;
    }



#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item-has-children > a.mega-menu-link > span.mega-indicator:after {
    content: '\f140';
    font-family: dashicons;
    font-weight: normal;
    display: inline-block;
    margin: 0 0 0 12px;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: rotate(0);
    color: inherit;
    position: relative;
    background: transparent;
    height: 0px;
    width: 0px;
    right: auto;
    line-height: inherit;
    z-index: -1;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-icon-right > a.mega-menu-link:before {
    float: right;
    margin: 3px 0 0 6px;}



#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {
    
    
    padding: 5px;
    }




#mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on.mega-icon-right > a.mega-menu-link:before {
    transform:rotate(180deg);
}

#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
    bottom: 0px;
}

#mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-menu-1 #mega-menu-menu-1 li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu {
    margin-top: 2px;
}


.big-heading-hover-effect{
  display:inline-block;
  color: #000;
  cursor: pointer;
}
.sub{
color: #666666;
 width: 100%;
 clear: both;
 font-size:16px; 
 padding-bottom:16px; 
 font-weight:300;
}







.big-heading-hover-effect:hover .sub2 {
 color: #2ab1ac;
}

.big-heading-hover-effect:hover .sub i {
 color: #2ab1ac;
 transform: scale(1.1, 1.0);
 padding-left: 2px;
}

.sub2{
  font-size: 22px;
  font-weight: normal;
  color: #000;
}

.big-heading-menu-link {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

/*.sub i {
display: none;
}

.sub:hover i {
  display: block;
  
}*/


    .mega-menu-menu-1-mobile-open {
        overflow: hidden;
    }

     .mega-menu-menu-1-mobile-open #mega-menu-wrap-menu-1  {
        height: 120vh;
        overflow-x: hidden;
    }

      #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
            text-underline-offset: 5px !important;
      }


.search-position {
    margin-right: 95px !important;
    position: absolute;
    right: 32px;
    top: 10px;
}

.mega-menu-logo {
    width: 100px !important;
}      


     @media only screen and (min-width: 1200px) { 


         .hide-google-search  {
    display: none !important;
}

        
        .mobile-button {
        display: none !important;
        }

        .main-logo {
            display: none;
        }

        .hide-google-search {
            display: none;
        }

        #mega-menu-wrap-menu-1 {
            z-index: 9;
            top:30px;
        }

        .new-menu-zindex {
        z-index: 99999;
        }

        .languages {
            margin: 2px 23px 0 0;
        }

.learn-more-menu-two-line span {
    top: 101px;
    left: 220px;
}


.learn-more-menu-two-line::before {
    top: 95px;
    left: 210px;
}




.learn-more-menu-two-line::after {
    top: 104px;
    left: 210px;
}


    }


 @media only screen and (max-width: 1200px) {






#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner { 
        background-color: #2ab1ac !important;

}


#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after { 
        background-color: #2ab1ac !important;

}

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before { 
        background-color: #2ab1ac !important;

}


#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::before, #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-inner::after {
        width: 25px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}




#mega-menu-wrap-menu-1 #mega-menu-menu-1 input, #mega-menu-wrap-menu-1 #mega-menu-menu-1 img {
    max-width: 40%;
    width: 80px;
    }

    #mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block-0 .mega-toggle-animated-box {
        width: 30px !important;
    }


#mega-menu-wrap-menu-1 .mega-menu-toggle {
   
    height: 45px;
    
}


a.mega-menu-link {
        color: #000000 !important;
        }

         .menu-bottom-class a.mega-menu-link {
        width: 121px !important;
        text-align: center !important;
        color: #fff !important;
        margin-left: -5px !important;

    }

    .gsc-control-cse {
    padding: 8px 16px 16px 16px !important;
}



.main-logo {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-right: auto;
    z-index: 55;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    
}


        .mega-menu-logo {
   position: fixed ;
        top: 5px !important;
        left: 0px !important;
        width: 80px;
}


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link 
    {
    border-radius: 0;
    border: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    background: transparent !important;
    text-align: left;
    color: #000000 !important;
    font-size: 16px;
     text-underline-offset: 5px !important;

    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item {
        text-underline-offset: 5px !important;
    }


     .top-items-postion {
    float: right;
    position: fixed;
    right: 28px;
    top: 0px;

    }
    .mega-menu-bottom-class {
    background-color: transparent !important;
    color: #666666 !important;
    text-decoration: underline !important;
    }


.languages .current {
    font-weight: 900 !important;
}


.languages {
    margin: 0px;
   padding:  0px 24px 0 0;
    
}




.languages.expand .current {
    position: relative;
    padding: 0 25px 0 0;
    background-color: transparent;
    border: 0;
    color: #000 !important;
    cursor: pointer;
    background: #fff;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
}

.languages .current {
    padding: 5px 20px;
}

.hover .languages .current::before {
    border-top-color: #fff; 
     border-right-color: #fff;
}

.reverse-colors .languages .current::before {
     border-top-color: #fff; 
     border-right-color: #fff;
}


.mega-menu-menu-1-mobile-open .reverse-colors .languages .current::before {
     border-top-color: #000; 
     border-right-color: #000;
}

.languages.expand .current::before, .reverse-colors .languages.expand .current::before, .revert-colors .languages.expand .current::before, .show-searchbar .languages.expand .current::before {
    border-top-color: #000; 
     border-right-color: #000;
}

 /* 
.languages ul {
    left: calc(50% - 24px);
    
}
*/




.no-touch .close-btn-white, .no-touch .languages a {
    font-weight: 900 !important;
}

.mega-menu-menu-1-mega-sticky {
    background-color: #fff !important;
}

.mega-stuck {
    background-color: transparent !important;
}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: #fff !important;
    padding: 0px 8px 0px 8px !important;
}










.header .top-links {
    display: none;
}

.reverse-colors.new-menu-zindex {
    z-index: 99;
   
    padding-top: 8px;
    padding-left: 8px;
}
.header {
    
    padding-top: 8px;
    padding-left: 8px;
}


    .active {
        background-color: transparent !important;
    }


   #mega-menu-wrap-menu-1 .mega-menu-toggle.mega-menu-open + #mega-menu-menu-1 {
    display: block;
    height: 100vh;

}

#mega-menu-wrap-menu-1 .mega-menu-toggle .mega-toggle-block {

    z-index: 9999;
}


.button-center {
    text-align: center !important;
    margin: 0 auto !important;
    margin: 0 auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    width: 120px !important;
}

.dark-text .languages .current {
    color: #fff ;
}



}

@media only screen and (max-width: 750px) 

{

   .top-items-postion {
    
    top: 4px;

    }
}


@media only screen and (min-width: 1600px) 

{


.sub2{
  padding-top: 4px;
}


.search-position {
    margin-right: 135px !important;
    position: absolute;
    right: 24px;
    top: 10px;
} 


.no-click-title {
    font-weight:600;
    font-size: 18px; color:#000;
  
    line-height:23px;

    }


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-link {
    font-size: 20px !important;
    }


    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
        font-size: 18px;
    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
        font-size: 18px;
    }

    #mega-menu-wrap-menu-1 #mega-menu-menu-1 {
    visibility: visible;
    text-align: left;
    padding: 0px 150px 0px 150px;


}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 150px 0px 150px !important;
}


.mega-menu-logo {
    width: 120px !important;
}


/*.menu-bottom-class a.mega-menu-link{

 {
    width: 150px !important;
   
}

}*/

.top-nav-links {
    padding-right: 32px;
    font-size: 18px;
}

.header .top-links {
    margin-top: 0px;
    margin-right: 330px;
    
}

.top-items-postion {
    right: 120px;
    top: 0px;
}



}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
    margin-top: 2px;
}

#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 > li.mega-menu-megamenu > ul.mega-sub-menu {
    margin-top: 2px;
}


#mega-menu-wrap-menu-1.mega-sticky #mega-menu-menu-1 {
    background: rgba(34, 34, 34, 0);
    padding: 0px 36px 0px 36px;
}


/* padding */
.left-8 {
    padding-left: 8px;
}

.right-8 {
    padding-right: 8px;
}

.top-8 {
    padding-top: 8px !important;
}

.bot-8 {
    padding-bottom: 8px !important;
}


.left-16 {
    padding-left: 16px;
}

.right-16 {
    padding-right: 16px;
}

.top-16 {
    padding-top: 16px !important;
}

.top-16-menu {
    padding-top: 16px !important;
}

.mega-stuck .top-16-menu {
   padding-top: 4px !important;
}



.bot-16 {
    padding-bottom: 16px !important;
}



.top-24 {
    padding-top: 24px !important;
}

.left-32 {
    padding-left: 32px !important;
}


.right-28 {
    padding-right: 28px !important;
}

.right-32 {
    padding-right: 32px !important;
}

.top-32 {
    padding-top: 32px !important;
}

.bot-32 {
    padding-bottom: 32px !important;
}


.top-40 {
    padding-top: 28px !important;
}

.mega-stuck .top-40 {
     padding-top: 20px !important;
}

.left-64 {
    padding-left: 64px !important;

}



.right-64  {
    padding-right: 62px ;
}

.top-64  {
    padding-top: 64px !important;
}

.bot-64  {
    padding-bottom: 64px;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 p {
    margin-bottom: 0px ;
}

.getstarted-line-height > .mega-menu-link{
   line-height: 26px !important;
}

.hover-solutions {
    display: block;
}


.hover-solution:hover p.solutions-overview {
  display: none !important;
}


.sticky {
    top:-100px;
}

 @media only screen and (max-width: 1200px) { 

    .left-64 {
    padding-left: 32px !important;

}

.bot-mobile-72 {
    padding-bottom: 50% !important;
}

 }





#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
    padding-bottom: 4px;
}

.header {
    position: absolute;

}



.get-started-padding {
        padding: 0px 16px 0px 16px;
}

#mega-menu-wrap-menu-1.mega-sticky {
    z-index: 9999999999 !important;
}

#mega-menu-wrap-menu-1.mega-stuck #mega-menu-menu-1 > li.mega-menu-item > a.mega-menu-logo > img.mega-menu-logo {
    min-height: 100%;
}


.mega-menu-menu-1-mobile-open .languages .current {
    color: #000;
}


#mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-menu-1 #mega-menu-menu-1 > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
    background: rgba(51, 51, 51, 0);
    color: #2ab1ac;
    font-weight: bold;
    text-decoration: none;
    border-color: #fff;
}


  .header {
    -webkit-transition: background-color 0s linear,-webkit-transform 0s linear;
    transition: background-color 0s linear,transform 0s linear;
    transition: background-color 0s linear,transform 0s linear,-webkit-transform 0s linear;
}


  .mega-svg {
        filter: brightness(0) invert(1);
    }

     .mega-sticky-wrapper .mega-svg {
        filter: brightness(1) invert(0);
    }

.active .mega-svg {
        filter: brightness(1) invert(0);
    }

.mega-menu-toggle  .mega-menu-logo {
        filter: brightness(0) invert(1);
    }
.mega-menu-open .mega-menu-logo {
        filter: brightness(1) invert(0);
    }

.active .mega-menu-toggle  .mega-menu-logo {
       filter: brightness(1) invert(0);
    }

.mega-sticky-wrapper .mega-menu-toggle  .mega-menu-logo {
       filter: brightness(1) invert(0);
    }


    .learn-more-menu-two-line {
    position: relative;
    display: inline-block;
    padding-left: 31px;
    font-weight: 600;
    font-size: 18px;
}

.learn-more-menu-two-line span {
    position: absolute;
    top: 9px;
    left: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
}

.learn-more-menu-two-line::after, .learn-more-menu-two-line::before {
    content: '';
    position: absolute;
    background-color: currentColor;
}