* {
    box-sizing: border-box;

    &:hover, &:focus {
        outline: none;
    }

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html {
    min-height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    height: 100%;
    min-width: 320px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
p {
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

figcaption,
figure,
main {
    display: block;
}

figure {
    margin: 0;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 0.9em;
}

a {
    -webkit-text-decoration-skip: objects;
}

abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: bold;
}

i,
em {
    font-style: italic;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

fieldset {
    padding: 0;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline; /* 2 */
}

textarea {
    overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details,
menu {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}


table {
    border-collapse: collapse;
}
