// variables
$fabrikat: "fabrikat bold", sans-serif;
$fabrikat-bold: "fabrikat bold", sans-serif;
$source-sans: 'Source Sans Pro', sans-serif;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$databold: 700;

// colors
$white: #ffffff;
$white-origin: #fff;
$input-gray: #cdd3e0;
$lightgray: #d8d8d8;
$darkgray: #979797;
$darkblue: #1b2031;
$darkblue08: rgba(#1b2031, .8);
$black: #000000;
$turquoise: #2ab1ac;
$grey: #f6f6f6;
$pink: #f5269f;
$cornflower-blue: #5f87ff;
$purple: #be12ff;
$visited: #72009d;
$light-teal: #3fd7d1;
$red: #e40000;
$percian-green: #00B2A9;
$dark-teal: #00837C;
$haiti-blue: #221C35;
$alice-blue: #F5F9FD;
$clear-day: #E6F4F4;
$aquamarine: #B8E7E5;
$prelude: #DFD1EB;
$apricot: #fcd1b9;
$cobal-blue: #242F37;
$cosmic-latte: #FCF5DE;
$panache: #E6F4E7;
$mist: #E1E6FA;
$light-teal: #E6F4F4;
$light-purple: #f0e5f5;
$light-fuchsia: #FBE2F0;
$light-red: #FFD1D3;
$light-green: #DDF6EA;
$light-marine: #D8DEFE;
$light-dark-teal: #008675;
$light-rust: #FBE6E6;
$light-dataset-blue: #D7EDF6;
$linen: #FEEFE6;
$light-blue: #EBF4FA; 
//gradients

// grid settings
$gl-colCount: 24;
$gl-gridName: grid;
$gl-colName: col;
$gl-attributeName: class;
$gl-gutter: 30px; // Total left + right
$gl-gutter-vertical: 0;
$gl-mq-width: 'min-width';
$gl-mq-list: (
        xs: 320px,
        sm: 768px,
        md: 1200px,
        lg: 1800px
);

// media settings
$breakpoints: (
        mobile-landscape: 568px,
        tablet: 768px,
        tablet-landscape: 1024px,
        desktop: 1200px,
        medium-desktop: 1440px,
        large-desktop: 1800px,
        ultra-desktop: 2400px
);

$images-path: '../images/';

html {}

body {
    background: $white-origin;
    color: $haiti-blue;
    -webkit-font-smoothing: antialiased;
    font-family: $source-sans;
    font-weight: $regular;
    font-size: 16px;
    line-height: 24px;

    @include media(">=desktop") {
        font-size: 18px;
        line-height: 28px;
    }

    @include media(">=large-desktop") {
        font-size: 20px;
        line-height: 30px;
    }

    .show-hero-popup &,
    .nav-opened & {
        overflow: hidden !important;
    }

    &.sticky-footer {
        display: flex;
        flex-direction: column;

        & > * {
            max-width: 100%;
        }
    }

    &.safari {
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            font-weight: $medium;
        }

        &.touch {
            .button, input[type="submit"] {
                font-weight: $medium;
            }
        }
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $fabrikat;
    font-weight: $databold;
    color: $haiti-blue;

    .content > &,
    .content .hs_cos_wrapper & {
        margin: 20px 0px 10px 0px;

        &:first-child {
            margin-top: 0;
        }
    }
}

h1, .h1 {
    font-size: 45px;
    line-height: 50px;

    @include media('>=desktop') {
        font-size: 56px;
        line-height: 64px
    }

    @include media('>=medium-desktop') {
        font-size: 72px;
        line-height: 72px
    }

    @include media('>=large-desktop') {
        font-size: 90px;
        line-height: 100px
    }
}

h2, .h2 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;

    @include media('>=desktop') {
        font-size: 48px;
        line-height: 56px;
    }

    @include media('>=medium-desktop') {
        font-size: 56px;
        line-height: 66px;
    }

    @include media('>=large-desktop') {
        font-size: 70px;
        line-height: 80px;
    }
}

h2.press-release {

    font-family: $fabrikat;
    font-size: 30px;
    line-height: 110%;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 200;

    @include media(">=desktop") {
        font-size: 30px;
        line-height: 110%;
    }

    @include media(">=large-desktop") {
        font-size: 30px;
        line-height: 110%;
    }
}

h3, .h3 {
    font-size: 32px;
    line-height: 40px;

    @include media('>=desktop') {
        font-size: 40px;
        line-height: 50px;
    }

    @include media('>=medium-desktop') {
        font-size: 48px;
        line-height: 52px;
    }

    @include media('>=large-desktop') {
        font-size: 55px;
        line-height: 70px;
    }

    .content & {

        @include media(">=desktop") {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        @include media(">=large-desktop") {
            margin-top: 20px;
        }

        &:first-child {
            margin-top: 20px;
        }

        &:first-child:last-child {
            margin-bottom: 10px;
        }
    }

    .content.job-details & {

        @include media(">=desktop") {
            margin-top: 30px;
        }

        @include media(">=large-desktop") {
            font-size: 40px;
            line-height: 50px;
            margin-top: 41px;
        }
    }

}

h4, .h4 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0;

    @include media('>=medium-desktop') {
        font-size: 40px;
        line-height: 50px;
    }

    .content & {

        @include media(">=large-desktop"){
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 10px;
        }
    }

}

h5, .h5 {
    font-size: 24px;
    line-height: 32px;

    @include media('>=medium-desktop') {
        font-size: 35px;
        line-height: 42px;
    }

    .content & {
        margin-bottom: 25px;

        @include media(">=desktop"){
            margin-bottom: 10px;
        }

        @include media(">=large-desktop"){
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 10px;
        }
    }
}

h6, .h6 {
    font-size: 22px;
    line-height: 30px;

    @include media('>=medium-desktop') {
        font-size: 30px;
        line-height: 35px;
    }

    .content & {

        @include media(">=desktop"){
            margin-bottom: 10px;
        }

        @include media(">=large-desktop"){
            margin-bottom: 10px;
        }
    }
}

h6 a {
    color: $percian-green;
}

h6 a:hover {
    text-decoration: underline;
    color: $percian-green;
}

p {

    .content & {
        margin-bottom: 18px;

        @include media(">=desktop") {
            margin-bottom: 18px;
        }

        &:last-child {
            margin-bottom: 18px;
        }

        a {
            color: $turquoise;

            .no-touch &:hover {
                text-decoration: underline;
            }
        }


        a.capabilities {
            color: $darkblue08;

            .no-touch &:hover {
                text-decoration: none;
            }
        }

    }
}

strong {
    font-weight: $semibold;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;

    .content > &,
    .content p > & {
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;

        @include media(">=large-desktop") {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &:first-child {
            margin-top: 0;
        }

        &:first-child:last-child {
            margin-bottom: 30px;
        }
    }
}

ul {
    .content & {
        margin: 20px 0;
        margin-left: 40px;

        @include media(">=large-desktop") {
            margin: 10px 0 30px;
        }

        &:first-child {
            margin-top: 0;
        }

        &:first-child:last-child {
            margin-bottom: 10px;
        }

        li {
            position: relative;
            padding-left: 11px;
            margin-bottom: 10px;

            @include media(">=desktop") {
                margin-bottom: 10px;
                padding-left: 14px;
            }
            &:last-child {
                margin-bottom: 10px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $darkblue08;
                counter-increment: none;
                @include media(">=desktop") {
                    top: 11px;
                }
            }
        }
    }


    

    .content.job-details & {

        @include media(">=desktop"){
            margin: 30px 0;
        }

        li {
            margin: 8px 0px 8px 0px;

            &::before {
                //position: static;
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
                display: table-cell;
                //text-align: right;
                //padding-right: .3em;
            }

          
        }
    }

    .content.job-details h3 + & {
        @include media(">=desktop"){
            margin-top: -9px;
        }
    }
}

ol {
    counter-reset: counterName;
    margin-top: 10px;
    margin-left: 40px;
    list-style: decimal;

    li  {
        &:before {
            counter-increment: counterName;
            content: counters(counterName, "-") ". ";
        }

        .content & {
            margin-bottom: 10px;

            @include media(">=large-desktop") {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    ul,
    ol {
        margin: 0;
        padding-left: 20px;
    }
}

ol a {
    color: $percian-green;
}

ol a:hover {
    text-decoration: underline;
}

a {
    text-decoration: none;
    outline: none;
    font: inherit;
    color: inherit;

    &:focus,
    &:active {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    .content & {
        .no-touch & {
            transition: all .3s;

            &:hover {
                color: $percian-green;
            }
        }
    }
}

// forms

.form-group {
    display: flex;
    flex-direction: column-reverse;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="search"] {
    display: inline-block;
    height: 40px;
    padding: 5px 20px;
    border: 1px solid $input-gray;
    border-radius: 25px;
    font-size: 18px;
    vertical-align: middle;
    background: $white;
    color: $darkblue;
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 25px;

    @include media(">=desktop") {
        height: 45px;
        padding: 7px 20px;
    }

    @include media(">=large-desktop") {
        height: 50px;
        padding: 10px 20px;
    }

    @include placeholder {
        color: rgba($darkblue, .5);
    }

    & + label {
        margin-bottom: 7px;
    }

    &:focus {}

    &.wpcf7-not-valid {
        border-color: $red;

        & + label {
            color: $red;
        }
    }
}

textarea {
    display: inline-block;
    border: 1px solid $input-gray;
    vertical-align: middle;
    background: $white;
    color: $darkblue !important;
    outline: none;
    -webkit-appearance: none;
    resize: none;
    width: 100% !important;
    box-sizing: border-box!important;
    height: 100px!important;
    padding: 5px 20px!important;
    font-size: 18px!important;
    border-radius: 20px!important;
    font-family: $source-sans !important;


    @include media(">=desktop") {
        height: 120px!important;
        padding: 7px 20px!important;
    }

    @include media(">=large-desktop") {
        height: 160px!important;
        padding: 10px 20px!important;
    }

    @include placeholder {
        color: $darkblue !important;
    }

    & + label {
        margin-bottom: 7px;
    }

    &:focus {
        box-shadow: none!important;
        border-color: $input-gray!important;
    }

    &.wpcf7-not-valid {
        border-color: $red;

        & + label {
            color: $red;
        }
    }
}

select {
    display: inline-block;
    border: 1px solid gray;
    padding: 5px 20px;
    line-height: 30px;
    font-size: 16px;
    height: 42px;
    vertical-align: middle;
    background: $white;
    outline: none;
    &:focus {

    }
}

.button,
input[type="submit"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-size: 16px;
    padding: 5px 24px;
    min-height: 46px;
    text-align: center;
    background-color: $percian-green;
    border: 2px solid transparent;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    font-family: $source-sans;
    font-weight: $semibold;
    text-transform: uppercase;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    color: $white-origin;
    -webkit-appearance: none;
    transition: color .3s, background-color .3s, border-color .3s;

    .no-touch & {

        &:hover {
            background-color: $white-origin;
            border-color: $percian-green;
            color: $percian-green;

            svg {
                path {
                    transition: fill .3s linear;
                    fill: $percian-green;
                }
            }
        }
    }

    &.medium {
        min-height: 48px;
        padding: 5px 38px;
        line-height: 20px;
        font-size: 16px;
    }

    &.large {
        min-height: 60px;
        padding: 5px 64px;
        line-height: 30px;
        font-size: 18px;
        border-radius: 33px;
    }

    &.play-btn {

        svg {
            height: 24px;
            width: 24px;
            margin-right: 10px;

            path {
                fill: $white;
            }
        }

        .no-touch & {
            &:hover {
                background-color: $white-origin;
                border-color: $percian-green;
                color: $percian-green;

                svg {
                    path {
                        fill: $percian-green;
                    }
                }
            }
        }
    }

    &.white {
        background-color: $white-origin;
        color: $percian-green;

        .no-touch & {
            &:hover {
                background-color: $percian-green;
                color: $white-origin;
            }
        }

        &.border {
            border-color: $white-origin;
        }

        &.border-turquoise {
            border-color: $percian-green;
        }

        &.revert {
            background-color: $white-origin;
            color: $percian-green;
        }

        &.transparent {
            background-color: transparent;
            color: $white-origin;

            .no-touch &:hover {
                color: #000;
                background-color: $white-origin;
            }
        }

        &.transparent-turquoise {
            background-color: transparent;
            color: $percian-green;

            .no-touch &:hover {
                color: $percian-green;
                background-color: $white-origin;
            }
        }
    }

    &.border, &.primary {
        border-color: $percian-green;

        .no-touch & {
            &:hover {
                color: $percian-green;
                background-color: $white-origin;
            }
        }

        &.revert {
            border-color: $percian-green;
            color: $percian-green;
            background-color: rgba(0,0,0,0);

            svg {
                path {
                    fill: $percian-green;
                }
            }

            .no-touch & {
                &:hover {
                    color: #fff !important;
                    background-color: $percian-green;
                    border-color:$percian-green;

                    svg {
                        path {
                            fill: $white-origin;
                        }
                    }
                }
            }
        }
    }

    &.blue {
        background-color: $cornflower-blue;

        .no-touch & {

            &:hover {
                background-color: $percian-green;
                color: $white;
            }
        }
    }

    &.gray {
        background-color: $darkgray;

        .no-touch & {

            &:hover {
                background-color: $percian-green;
                color: $white;
            }
        }
    }

    &.light-gray {
        background-color: $lightgray;
        color: $darkblue;

        .no-touch & {

            &:hover {
                background-color: $percian-green;
                color: $white;
            }
        }
    }

    &.purple {
        background-color: #A77BCA;

        .no-touch & {

            &:hover {
                background-color: $percian-green;
                color: $white;
            }
        }
    }

    &.watch-video-btn {
        overflow: hidden;
        position: relative;

        .vidyard-player-container {
            position: absolute !important;
            top: 0;
            left: 0;
            opacity: 0;
        }

        & > img {
            &.vidyard-player-embed {
                &:first-child {
                    display: none !important;
                }
            }
        }
    }

    &:active,
    &:focus {
        color: $white;
    }
}

button {
    cursor: pointer;
    font-family: $source-sans;

    .content & {
        @extend .button;
    }
}

// .watch-video {
//     position: relative;
//     display: inline-block;
//     padding: 0 0 0 35px;
//     background-color: transparent;
//     border: none;
//     color: $white;
//     font-weight: $semibold;
//     font-size: 16px;
//     text-transform: uppercase;

//     @include media(">=large-desktop"){
//         font-size: 18px;
//     }

//     .hero & {
//         @include media(">=desktop", "<large-desktop"){
//             margin-right: 30px;
//         }
//     }

//     span {
//         position: absolute;
//         top: 50%;
//         left: 0;
//         transform: translateY(-50%);
//         width: 25px;
//         height: 25px;

//         @include media(">=large-desktop"){
//             width: 27px;
//             height: 27px;
//         }
//     }

//     svg {
//         width: 100%;
//         height: auto;
//         max-height: 100%;
//     }
// }

// button {
//     cursor: pointer;
//     font-family: $source-sans;

//     .content & {
//         @extend .button;
//     }
// }

// .watch-video-product {
//     position: relative;
//     display: inline-block;
//     padding: 0 0 0 35px;
//     background-color: transparent;
//     border: none;
//     color: $white;
//     font-weight: $semibold;
//     font-size: 16px;
//     text-transform: uppercase;

//     @include media(">=large-desktop"){
//         font-size: 18px;
//     }

//     .hero & {
//         @include media(">=desktop", "<large-desktop"){
//             margin-right: 30px;
//         }
//     }

//     span {
//         position: absolute;
//         top: 50%;
//         left: 0;
//         transform: translateY(-50%);
//         width: 25px;
//         height: 25px;

//         @include media(">=large-desktop"){
//             width: 27px;
//             height: 27px;
//         }
//     }

//     svg {
//         width: 100%;
//         height: auto;
//         max-height: 100%;
//     }
// }

.watch-video-web-series, 
.watch-video-people, 
.watch-video-product, 
.watch-video {
    position: relative;
    display: inline-block;
    padding: 0 0 0 35px;
    background-color: transparent;
    border: none;
    color: $white;
    font-weight: $semibold;
    font-size: 16px;
    text-transform: uppercase;

    @include media(">=large-desktop"){
        font-size: 18px;
    }

    .hero & {
        @include media(">=desktop", "<large-desktop"){
            margin-right: 30px;
        }
    }

    span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;

        @include media(">=large-desktop"){
            width: 27px;
            height: 27px;
        }
    }

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

// .watch-video-people {
//     position: relative;
//     display: inline-block;
//     padding: 0 0 0 35px;
//     background-color: transparent;
//     border: none;
//     color: $white;
//     font-weight: $semibold;
//     font-size: 16px;
//     text-transform: uppercase;

//     @include media(">=large-desktop"){
//         font-size: 18px;
//     }

//     .hero & {
//         @include media(">=desktop", "<large-desktop"){
//             margin-right: 30px;
//         }
//     }

//     span {
//         position: absolute;
//         top: 50%;
//         left: 0;
//         transform: translateY(-50%);
//         width: 25px;
//         height: 25px;

//         @include media(">=large-desktop"){
//             width: 27px;
//             height: 27px;
//         }
//     }

//     svg {
//         width: 100%;
//         height: auto;
//         max-height: 100%;
//     }
// }

.video-block {
    position: relative;

    .content & {
        margin-top: 30px;
        margin-bottom: 30px;

        @include media(">=desktop") {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        & > div {
            padding-top: 55.37%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        z-index: 2;
    }

    > div {
        height: 0;
        padding-top: 38.02%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        position: relative;
    }

    .watch-video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 10;
    }

     .watch-video-web-series {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 10;
    }

       .watch-video-product {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 10;
    }


    
}

.features-image {
    position: relative;

    .content & {
        margin-top: 30px;
        margin-bottom: 30px;

        @include media(">=desktop") {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        & > div {
            padding-top: 55.37%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //background: rgba(0, 0, 0, .4);
        z-index: 2;
    }

    > div {
        height: 0;
        padding-top: 38.02%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        position: relative;
    }

   
}

.close-btn {
    position: relative;
    display: inline-block;
    min-height: auto;
    min-width: auto;
    padding: 0 35px 0 0;
    border: none;
    background-color: transparent;
    color: $darkblue;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;

    .no-touch & {
        transition: color .3s;

        &:hover {
            color: $turquoise !important;
            background-color: transparent;

            &::before,
            &::after {
                background-color: $turquoise;
            }
        }
    }

    &:active,
    &:focus {
        color: $turquoise !important;

        &::before,
        &::after {
            background-color: $turquoise;
        }
    }

    @include media(">=large-desktop") {
        padding: 0 50px 0 0;
        font-size: 16px;
        line-height: 30px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 35px;
        height: 1px;
        background-color: $darkblue;
        transition: background-color .3s;

        @include media(">=large-desktop") {
            width: 50px;
        }
    }

    &::before {
        transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
        transform: translateY(-50%) rotate(45deg);
    }
}

.close-btn-white {
    position: relative;
    display: inline-block;
    min-height: auto;
    min-width: auto;
    padding: 0 35px 0 0;
    border: none;
    background-color: transparent;
    color: $darkblue;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;

    .no-touch & {
        transition: color .3s;

        &:hover {
            color: $turquoise !important;
            background-color: transparent;

            &::before,
            &::after {
                background-color: $turquoise;
            }
        }
    }

    &:active,
    &:focus {
        color: $turquoise !important;

        &::before,
        &::after {
            background-color: $turquoise;
        }
    }

    @include media(">=large-desktop") {
        padding: 0 50px 0 0;
        font-size: 16px;
        line-height: 30px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 35px;
        height: 1px;
        background-color: #fff;
        transition: background-color .3s;

        @include media(">=large-desktop") {
            width: 50px;
        }
    }

    &::before {
        transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
        transform: translateY(-50%) rotate(45deg);
    }
}

.learn-more {
    position: relative;
    display: inline-block;
    padding-left: 31px;
    font-weight: $semibold;
    color: $haiti-blue;
    font-size: 16px;
    line-height: 20px;
    // font-size: 14px;
    //white-space: nowrap;

    .no-touch & {

        &:hover {

            &::before {
                transform: scaleY(0);
            }

            &::after {
                width: 20px;
            }

            span {
                margin-left: 3px;
            }
        }
    }

    &.uppercase {
        text-transform: uppercase;

        span {
            top: 6px;
        }

        &::after {
            top: 9px;
        }

        &::before {
            top: 2px;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: $haiti-blue;
    }

    &::before {
        top: 3px;
        left: 0;
        height: 9px;
        width: 2px;

        .no-touch & {
            transform-origin: bottom;
            transition: transform .2s linear;
        }
    }

    &::after {
        top: 10px;
        left: 0;
        width: 18px;
        height: 2px;

        .no-touch & {
            transition: width .2s linear;
        }
    }

    span {
        position: absolute;
        top: 7px;
        left: 10px;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 2px solid $haiti-blue;
        border-right: 2px solid $haiti-blue;

        .no-touch & {
            transition: margin-left .2s linear;
        }
    }
}

.learn-more-menu {
    position: relative;
    display: inline-block;
    padding-left: 31px;
    font-weight: $semibold;
    font-size: 18px;
    //white-space: nowrap;

    @include media(">=large-desktop") {
        
        font-size: 20px;
    }

    .no-touch & {

        &:hover {

            &::before {
                transform: scaleY(0);
            }

            &::after {
                width: 20px;
            }

            span {
                margin-left: 3px;
            }
        }
    }

    &.uppercase {
        text-transform: uppercase;
        font-size: 18px;

        &::before {
            top: 4px;

            @include media(">=desktop") {
                top: 6px;
            }

            @include media(">=large-desktop") {
                top: 7px;
            }
        }

        &::after {
            top: 11px;

            @include media(">=desktop") {
                top: 13px;
            }

            @include media(">=large-desktop") {
                top: 14px;
            }
        }

        span {
            top: 8px;

            @include media(">=desktop") {
                top: 91px;
            }

            @include media(">=large-desktop") {
               top: 91px;
            }
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: currentColor;
    }

    &::before {
        top: 5px;
        left: 0;
        height: 9px;
        width: 2px;

        @include media(">=desktop") {
            top: 65px;
            left: 210px;
        }

        @include media(">=large-desktop") {
             top: 65px;
        }

        .no-touch & {
            transform-origin: bottom;
            transition: transform .2s linear;
        }
    }

    &::after {
        top: 12px;
        left: 0;
        width: 18px;
        height: 2px;

        @include media(">=desktop") {
            top: 74px;
            left: 210px;
        }

        @include media(">=large-desktop") {
            top: 74px;
        }

        .no-touch & {
            transition: width .2s linear;
        }
    }

    span {
        position: absolute;
        top: 9px;
        left: 10px;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        border-top: 2px solid currentColor;
        border-right: 2px solid currentColor;

        @include media(">=desktop") {
            top: 71px;
            left: 220px;
        }

        @include media(">=large-desktop") {
            top: 12px;
             top: 71px;
        }

        .no-touch & {
            transition: margin-left .2s linear;
        }
    }
}

.learn-more-menu:hover {
    color: $percian-green;
}

.get-started {
    display: inline-block;
    border-bottom: 2px solid $white;
    font-family: $source-sans;
    font-size: 25px;
    font-weight: $semibold;
    text-transform: uppercase;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    color: $white;
    -webkit-appearance: none;


@include media(">=desktop"){
        transition: color .3s, border-color .3s;
        font-size: 16px;
    }

    @include media(">=large-desktop") {
        font-size: 18px;
    }





    /*&:hover {
        @include media(">=desktop"){
            color: rgba($white, .65);
            border-color: rgba($white, .65);
        }
    }*/

    &:active,
    &:focus {
        color: inherit;
    }
}

input[type="checkbox"] {
    display: none;

    &:checked {

        & + label {

            &::before {
                background-color: $percian-green;
                border-color: $percian-green !important;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    & + label {
        position: relative;
        padding-left: 34px;
        color: $haiti-blue;
        font-weight: $semibold;
        font-size: 18px;
        line-height: 36px;
        cursor: pointer;

        .no-touch & {

            &:hover {

                &::before {
                    border-width: 2px;
                }
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            width: 24px;
            height: 24px;
            border-radius: 2px;
        }

        &::before {
            border: 1px solid rgba($haiti-blue, .8);
            transition: background-color .3s, border-color .3s;
        }

        &::after {
            background-size: 10px 8px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3E%3Cpath fill='%23fff' transform='translate(-192 -5924)' d='M192.22 5929.279c-.673-.667.351-1.682 1.025-1.014l3.007 2.977 6.358-6.998c.636-.7 1.712.259 1.076.958l-6.895 7.588a.73.73 0 0 1-1.024 0z'/%3E%3C/svg%3E");
            transition: opacity .3s;
            opacity: 0;
        }
    }
}

input[type="radio"] {
    display: none;

    &:checked {

        & + label {

            &::before {
                border-color: #02ACD4;
                border-width: 1px !important;
                background-color: $percian-green;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    & + label {
        position: relative;
        padding-left: 34px;
        color: $haiti-blue;
        font-weight: $semibold;
        font-size: 18px;
        line-height: 36px;
        cursor: pointer;

        .no-touch & {

            &:hover {

                &::before {
                    border-width: 2px;
                }
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
        }

        &::before {
            top: 6px;
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid rgba($haiti-blue, .8);
            transition: border-color .3s;
        }

        &::after {
            top: 13px;
            left: 7px;
            width: 10px;
            height: 10px;
            background-color: $white-origin;
            transition: opacity .3s;
            opacity: 0;
        }
    }
}

label {
    display: inline-block;
    vertical-align: middle;
    color: $darkblue;
}

.container,
.blog-details-container {
    width: 100%;
    padding: 0 10px;
    clear:both;

    @include media(">=desktop") {
        max-width: 1300px;
        margin: 0 auto;
    }

    @include media(">=large-desktop") {
        max-width: 1600px;
    }

    &.small {
        @include media(">=desktop") {
            max-width: 960px;
        }
    }

    &.big {
        @include media(">=desktop") {
            max-width: 1200px;
        }

        @include media(">=medium-desktop") {
            max-width: 1420px;
        }

        @include media(">=large-desktop") {
            max-width: 1760px;
        }
    }

    &.updated {
        padding: 0 20px;

        @include media('>=desktop') {
            padding: 0 64px;
            max-width: 100%;
        }

        @include media('>=medium-desktop') {
            padding: 0 80px;
        }

        @include media('>=large-desktop') {
            padding: 0 170px;
            max-width: 1920px;
            margin: 0 auto;
        }
    }
}

.blog-details-container {
    @include media(">=large-desktop"){
        max-width: 960px;
    }
}

.text-article-title {

    .content & {
        text-align: center;
        margin-bottom: 30px;

        @include media(">=desktop") {
            margin-bottom: 30px;
        }

        @include media(">=large-desktop") {
            margin-bottom: 30px;
        }
    }

}

.call_to-action-webinars-hero { 
    position: absolute;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-size: 0;
    padding: 0;
    min-width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-appearance: initial!important;
    -moz-appearance: initial!important;
    appearance: initial!important; } 

// blockquote {
//     border: none!important;
//     position: relative;
//     max-width: 100%;
//     margin: 30px 0;
//     padding-top: 45px;

//     @include media(">=desktop") {
//         padding-top: 60px;
//     }

//     @include media(">=large-desktop"){
//         margin: 30px 0;
//     }

//     &::before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         transform: rotate(180deg);
//         width: 47px;
//         height: 35px;
//         background-size: contain;
//         background-repeat: no-repeat;
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 35'%3E%3Cg fill='%235f87ff' transform='translate(-682 -4248)'%3E%3Cpath d='M708 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3Cpath d='M682.04 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3C/g%3E%3C/svg%3E");
//     }

//     q {
//         font-weight: $semibold;
//         quotes: none;
//         font-size: 18px;
//         line-height: 28px;
//         color: $darkblue;

//         @include media(">=desktop") {
//             font-size: 20px;
//             line-height: 30px;
//         }

//         @include media(">=large-desktop") {
//             font-size: 22px;
//             line-height: 32px;
//         }

//         &:before,
//         &:after {
//             quotes: none;
//         }
//     }

//     cite {
//         display: block;
//         margin-top: 15px;
//         color: $darkblue;
//         font-style: normal;
//         font-weight: $semibold;
//         font-size: 14px;
//         line-height: 24px;

//         @include media(">=desktop") {
//             font-size: 16px;
//             line-height: 26px;
//         }

//         @include media(">=large-desktop") {
//             margin-top: 19px;
//             font-size: 18px;
//             line-height: 28px;
//         }

//         span {
//             display: block;
//             color: rgba($darkblue, .65);
//         }
//     }

//     &.fact {
//         //max-width: 430px;
//         padding: 12px 0 0 30px;
//         background-size: 93px 70px;
//         background-repeat: no-repeat;
//         background-position: left top;
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E");

//         &::before {
//             display: none;
//         }

//         q {
//             font-size: 16px;
//             line-height: 26px;

//             @include media(">=desktop") {
//                 font-size: 18px;
//                 line-height: 28px;
//             }

//             @include media(">=large-desktop") {
//                 font-size: 20px;
//                 line-height: 30px;
//             }

//             &:before,
//             &:after {
//                 quotes: none;
//             }
//         }

//         cite {
//             font-size: 12px;
//             line-height: 22px;

//             @include media(">=desktop") {
//                 font-size: 14px;
//                 line-height: 24px;
//             }

//             @include media(">=large-desktop") {
//                 font-size: 16px;
//                 line-height: 26px;
//             }
//         }
//     }

//     &.article-quote {
//         max-width: 780px;
//         margin: 0 auto;
//         padding-top: 26px;
//         text-align: center;
//         background-position: center top;
//         background-size: 120px 90px;
//         min-height: 92px;
//         background-repeat: no-repeat;
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E");

//         @include media(">=desktop") {
//             background-size: 150px 113px;
//             min-height: 115px;
//         }

//         @include media(">=large-desktop") {
//             background-size: 198px 149px;
//             min-height: 150px;
//         }






//         .content & {
//             margin-bottom: 30px;
//             @include media(">=desktop") {
//                 margin-bottom: 30px;
//             }
//             @include media(">=large-desktop") {
//                 margin-top: 75px;
//             }
//         }






//         .blog-post-detail & {
//             @include media(">=desktop"){
//                 margin-bottom: 90px;
//             }

//             @include media(">=large-desktop"){
//                 margin-top: 60px;
//             }
//         }
//         &::before {
//             display: none;
//         }
//     }

//     &.article-fact {
//         //max-width: none;
//         padding-top: 0;
//         padding-left: 19px;

//         &::before {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 3px;
//             height: 100%;
//             background-image: none;
//             background-color: $cornflower-blue;
//         }

//         p {
//             font-size: 14px;
//             line-height: 24px;
//             color: $darkblue08;

//             @include media(">=desktop") {
//                 font-size: 16px;
//                 line-height: 26px;
//             }

//             @include media(">=large-desktop") {
//                 font-size: 18px;
//                 line-height: 28px;
//             }
//         }
//     }

//     a {
//         color: $turquoise;

//         .no-touch &:hover {
//             text-decoration: underline;
//         }
//     }
// }

a.facts {
        color: $turquoise;

        .no-touch &:hover {
            text-decoration: underline;
        }
    }

blockquote {
    border: none!important;
    position: relative;
    max-width: 100%;
    margin: 30px 0;
    padding-top: 45px;

    @include media(">=desktop") {
        padding-top: 60px;
    }

    @include media(">=large-desktop"){
        margin: 30px 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(180deg);
        width: 47px;
        height: 35px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 35'%3E%3Cg fill='%235f87ff' transform='translate(-682 -4248)'%3E%3Cpath d='M708 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3Cpath d='M682.04 4279.66a25.777 25.777 0 0 0 5.593-2.35c1.734-1.062 3.244-2.237 4.53-3.523a15.582 15.582 0 0 0 3.02-4.25c.727-1.566 1.119-3.132 1.119-4.698 0-.951-.168-1.79-.56-2.517-.391-.727-.838-1.063-1.398-1.063-.559 0-1.006.112-1.398.336-.391.223-.783.447-1.174.727-.392.28-.84.503-1.343.727-.503.224-1.174.335-2.013.335-1.734 0-3.244-.67-4.474-2.069-1.287-1.398-1.902-3.188-1.902-5.313 0-2.237.839-4.139 2.573-5.649 1.733-1.51 3.803-2.293 6.32-2.293 1.622 0 3.132.336 4.474 1.063a11.68 11.68 0 0 1 3.691 2.964c1.063 1.23 1.902 2.684 2.517 4.306.615 1.622.895 3.412.895 5.314 0 2.964-.56 5.704-1.622 8.221-1.119 2.517-2.573 4.698-4.474 6.544a21.575 21.575 0 0 1-6.544 4.474 22.34 22.34 0 0 1-7.83 1.901z'/%3E%3C/g%3E%3C/svg%3E");
    }

    q {
        font-weight: $semibold;
        quotes: none;
        font-size: 18px;
        line-height: 28px;
        color: $darkblue;

        @include media(">=desktop") {
            font-size: 20px;
            line-height: 30px;
        }

        @include media(">=large-desktop") {
            font-size: 22px;
            line-height: 32px;
        }

        &:before,
        &:after {
            quotes: none;
        }
    }

    cite {
        display: block;
        margin-top: 15px;
        color: $darkblue;
        font-style: normal;
        font-weight: $semibold;
        font-size: 14px;
        line-height: 24px;

        @include media(">=desktop") {
            font-size: 16px;
            line-height: 26px;
        }

        @include media(">=large-desktop") {
            margin-top: 19px;
            font-size: 18px;
            line-height: 28px;
        }

        span {
            display: block;
            color: rgba($darkblue, .65);
        }
    }

    &.fact {
        //max-width: 430px;
        padding: 12px 0 0 30px;
        background-size: 93px 70px;
        background-repeat: no-repeat;
        background-position: left top;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E");

        &::before {
            display: none;
        }

        q {
            font-size: 16px;
            line-height: 26px;

            @include media(">=desktop") {
                font-size: 18px;
                line-height: 28px;
            }

            @include media(">=large-desktop") {
                font-size: 20px;
                line-height: 30px;
            }

            &:before,
            &:after {
                quotes: none;
            }
        }

        cite {
            font-size: 12px;
            line-height: 22px;

            @include media(">=desktop") {
                font-size: 14px;
                line-height: 24px;
            }

            @include media(">=large-desktop") {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    &.article-quote {
        max-width: 780px;
        margin: 0 auto;
        padding-top: 26px;
        text-align: center;
        background-position: center top;
        background-size: 120px 90px;
        min-height: 92px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E");

        @include media(">=desktop") {
            background-size: 150px 113px;
            min-height: 115px;
        }

        @include media(">=large-desktop") {
            background-size: 198px 149px;
            min-height: 150px;
        }






        .content & {
            margin-bottom: 30px;
            @include media(">=desktop") {
                margin-bottom: 30px;
            }
            @include media(">=large-desktop") {
                margin-top: 75px;
            }
        }






        .blog-post-detail & {
            @include media(">=desktop"){
                margin-bottom: 90px;
            }

            @include media(">=large-desktop"){
                margin-top: 60px;
            }
        }
        &::before {
            display: none;
        }
    }

    &.solutions-quote {
        max-width: 780px;
        margin: 0 auto;
        padding-top: 26px;
        text-align: center;
        background-position: center top;
         background-size: 100px 76px;
        min-height: 92px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 93 70'%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M221.99 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3Cpath fill='%235f87ff' opacity='.3' transform='rotate(180 131.5 2173.5)' d='M170.09 4340.31c3.915-1.12 7.606-2.685 11.186-4.699 3.467-2.125 6.487-4.474 9.06-7.047a31.165 31.165 0 0 0 6.04-8.501c1.454-3.132 2.237-6.264 2.237-9.396 0-1.902-.335-3.58-1.118-5.034-.783-1.454-1.678-2.125-2.797-2.125-1.118 0-2.013.224-2.796.671-.783.448-1.566.895-2.349 1.454-.783.56-1.678 1.007-2.684 1.455-1.007.447-2.35.67-4.027.67-3.468 0-6.488-1.342-8.949-4.138-2.573-2.796-3.803-6.376-3.803-10.626 0-4.475 1.678-8.278 5.145-11.298 3.468-3.02 7.607-4.586 12.64-4.586 3.244 0 6.264.671 8.949 2.125 2.796 1.454 5.257 3.356 7.382 5.929 2.126 2.46 3.803 5.369 5.034 8.613 1.23 3.244 1.79 6.823 1.79 10.626 0 5.928-1.119 11.41-3.244 16.443-2.237 5.034-5.146 9.396-8.949 13.087-3.691 3.692-8.053 6.712-13.087 8.949-5.034 2.237-10.179 3.467-15.66 3.803z'/%3E%3C/svg%3E");

        @include media(">=desktop") {
            background-size: 100px 76px;
            min-height: 100px;
        }

        @include media(">=large-desktop") {
             background-size: 100px 76px;
            min-height: 150px;
        }


        

        

        .content & {
            margin-bottom: 30px;
            @include media(">=desktop") {
                margin-bottom: 30px;
            }
            @include media(">=large-desktop") {
                margin-top: 75px;
            }
        }






        .blog-post-detail & {
            @include media(">=desktop"){
                margin-bottom: 90px;
            }

            @include media(">=large-desktop"){
                margin-top: 60px;
            }
        }
        &::before {
            display: none;
        }
    }

    &.article-fact {
        //max-width: none;
        padding-top: 0;
        padding-left: 19px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background-image: none;
            background-color: $cornflower-blue;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            color: $darkblue08;

            @include media(">=desktop") {
                font-size: 16px;
                line-height: 26px;
            }

            @include media(">=large-desktop") {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    a {
        color: $turquoise;

        .no-touch &:hover {
            text-decoration: underline;
        }
    }
}

figure {
    .content & {
        position: relative;
        display: block;
        width: auto!important;
        margin: 10px 0 20px;
        text-align: center;

        @include media(">=large-desktop") {
            margin: 10px 0 10px;
        }

        img:not([class]) {
            display: inline-block;
            max-width: 100%;
            height: auto;
            margin: 0 0 5px 0;

            @include media(">=desktop") {
                margin-bottom: 5px;
            }
        }

        figcaption {
            font-family: $source-sans;
            color: rgba($darkblue, .5);
            font-weight: $semibold;
            font-size: 14px;
            line-height: 20px;

            @include media(">=desktop") {
                font-size: 16px;
                line-height: 24px;
            }

            @include media(">=large-desktop") {
                font-size: 18px;
                line-height: 32px;
            }
        }
    }

    .blog-post-detail & {
        text-align: center;

        @include media(">=desktop"){
            /*max-width: 1440px;*/
            margin-left: calc((100vw - 940px) / -2 + 10px);
            margin-right: calc((100vw - 940px) / -2 + 10px);
        }

        @include media(">=medium-desktop"){
            margin-left: -240px;
            margin-right: -240px;
        }

        img {
            @include media(">=desktop"){
                margin-bottom: 17px;
            }
        }

        figcaption {
            text-align: center;

            @include media(">=desktop") {
                margin: 0 calc((100vw - 940px) / 2 - 10px);
            }

            @include media(">=medium-desktop") {
                margin-left: 240px;
                margin-right: 240px;
            }
        }
    }
}

table {

    .content & {
        font-family: $source-sans;
        color: $darkblue;
        font-weight: $regular;
        //width: 100%;
        border: none;
        border-top: 1px solid rgba($darkblue, .25);
        text-align: left;
        margin: 30px 0;

        @include media(">=desktop") {
            margin: 30px 0 60px;
        }
        @include media(">=large-desktop") {
            margin-bottom: 120px;
        }

        tr {
            border-bottom: 1px solid rgba($darkblue, .25);
        }

        td, th {
            padding: 5px 0 6px;

            @include media(">=desktop") {
                padding: 11px 0 12px;
            }
        }

        th {
            width: 34%;
            padding-right: 10px;
            font-weight: 900;
            font-size: 14px;
            line-height: 24px;
            padding-left:15px;

            @include media(">=desktop") {
                font-size: 18px;
                line-height: 28px;
            }

            @include media(">=large-desktop") {
                font-size: 20px;
                line-height: 30px;
            }
        }

        td {
            font-size: 16px;
            line-height: 26px;

            @include media(">=desktop") {
                font-size: 20px;
                line-height: 30px;
            }

            @include media(">=large-desktop") {
                font-size: 22px;
            }
        }

        a {
            text-decoration: underline;

            .no-touch &:hover {
                text-decoration: none;
            }
        }
    }
}

.content ul li a {color: $percian-green;}
.content ul li a:hover {text-decoration: underline;}

.content-image {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        object-fit: cover;
    }
}

.sticky-parent {
    position: relative;
    

    &.indent {
        .sticky-element {

        }
    }



    .sticky-element {

        &.fixed {
            position: fixed;
            overflow: hidden;
            max-height: calc(100vh - 155px);
            margin-bottom: 0;

            .show-announcement & {
                @include media(">=desktop"){
                    max-height: calc(100vh - 205px);
                }
            }
        }

        &.bottom {
            position: absolute!important;
            top: auto!important;
            left: 0;
            bottom: 0;
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.sticky-parent-online {
    position: relative;
    

    &.indent {
        .sticky-element-online {

        }
    }



    .sticky-element-online {

        &.fixed {
            position: fixed;
            overflow: hidden;
            max-height: calc(100vh - 155px);
            margin-bottom: 0;

            .show-announcement & {
                @include media(">=desktop"){
                    max-height: calc(100vh - 205px);
                }
            }
        }

        &.bottom {
            position: absolute!important;
            top: auto!important;
            left: 0;
            bottom: 0;
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.has-parallax {
    position: relative;
    //transition: transform .5s linear;
}

#vidyard-popbox {
    width: 95%;
    max-width: 1800px;
    height: auto;
}

#vidyard-content-fixed {

    #vidyard-popbox {
        height: auto !important;
    }
}

#vidyard-overlay-wrapper {
    z-index: 2000;
}

@media print {

    .image-section,
    .header .announcement-bar,
    .header .wrap > *:not(.main-logo),
    .explore-links,
    .download-block,
    .report-section,
    .page-title,
    .button,
    .questions-widget,
    .sharing-block,
    .footer {
        display: none !important;
    }

    .main {
        padding-top: 0;

        & > *:first-child{
            margin-top: 0;
        }
    }


    .header {
        position: static!important;
        height: 145px!important;
        margin-bottom: 20px!important;

        .main-logo {

            img.white {
                display: none!important;
            }

            img.green {
                display: block!important;
            }
        }
    }
}

.left75 {
    margin-left: 75px;
    }

.bot200 {
    padding-bottom: 200px;
} 

.bot100 {
    padding-bottom: 100px;
}     

// combination styles
h2 + h3 {
    margin-top: -10px !important;
   
}

h2 + h4 {
    margin-top: -10px !important;
   
}

h2 + h5 {
    margin-top: -10px !important;
   
}

h2 + h6 {
    margin-top: -10px !important;
   
}

h3 + h4 {
    margin-top: -10px !important;
}

h3 + h5 {
    margin-top: -10px !important;
}

h3 + h6 {
    margin-top: -10px !important;
}

h4 + h5 {
    margin-top: -10px !important;
}

h4 + h6 {
    margin-top: -10px !important;
}

h5 + h6 {
    margin-top: -10px !important;
}

span[style] {
    line-height: 28px;
}

.sticky-element.fixed {
    top:120px;
}

.indent-top {
    margin-top: 80px;

    @include media('>=desktop') {
        margin-top: 160px;
    }

    @include media('>=medium-desktop') {
        margin-top: 180px;
    }

    @include media('>=large-desktop') {
        margin-top: 200px;
    }
}

.indent-top-medium {
    margin-top: 80px;

    @include media('>=desktop') {
        margin-top: 100px;
    }

    @include media('>=large-desktop') {
        margin-top: 120px;
    }
}

.indent-bottom {
    margin-bottom: 80px;

    @include media('>=desktop') {
        margin-bottom: 160px;
    }

    @include media('>=medium-desktop') {
        margin-bottom: 180px;
    }

    @include media('>=large-desktop') {
        margin-bottom: 200px;
    }
}

.indent-bottom-medium {
    margin-bottom: 80px;

    @include media('>=desktop') {
        margin-bottom: 100px;
    }

    @include media('>=large-desktop') {
        margin-top: 120px;
    }
}

.has-indicators {
    padding: 0 35px;
    max-width: 320px;
    margin: 0 auto;

    @include media('>=tablet') {
        margin-left: 0;
    }

    @include media('>=desktop') {
        max-width: 330px;
    }

    @include media('>=medium-desktop') {
        padding: 0 40px;
    }

    @include media('>=large-desktop') {
        max-width: 328px;
    }

    .indicator-item {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        width: 48px !important;
        height: 48px;
        outline: none;
        transition: transform .3s ease .3s;
        cursor: pointer;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 2;
            opacity: 0;
            transition: opacity .3s ease .3s;

            circle {
                stroke: $percian-green;
                stroke-dasharray: 151px;
                stroke-dashoffset: 151px;
                transform: rotate(-90deg) translate(-100%, 0%);
            }
        }
    }

    .indicator-item-img-hld {
        border: 6px solid $cobal-blue;
        border-radius: 50%;
        overflow: hidden;
    }

    .slick-slide {
        & > div {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            transition: transform .3s ease;

            .no-touch & {
                &:hover {
                    transform: scale(1.05) translateY(-2px);
                }
            }
        }
    }

    .slick-current {
        .indicator-item {
            transform: scale(1.25) translateY(-10%);

            svg {
                opacity: 1;

                circle {
                    stroke-dashoffset: 0;
                    transition: stroke-dashoffset 7s linear .3s;
                }
            }
        }

        & > div {
            .no-touch & {
                &:hover {
                    transform: scale(1) translateY(0);
                }
            }
        }
    }

    .prev, .next {
        background-color: rgba(0,0,0,0);
        border: none;
        position: absolute;
        right: -1px;
        top: 39%;

        &.slick-disabled {
            opacity: .4;
        }

        @include media('>=large-desktop') {
            top: 32%;
        }
    }

    .next {
        @include media('>=large-desktop') {
            right: 2px;
        }
    }

    .prev {
        left: -8px;
        right: auto;

        @include media('>=large-desktop') {
            left: -4px;
        }

        svg {
            transform: rotate(-180deg);
        }
    }
}